<!--
 * @Author: Jerry
 * @Date: 2021-03-12 15:58:38
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-24 16:58:50
 * @Description: 运单上传
-->

<template>
  <div>
    <img v-if="imgSrc || value" :src="imgSrc || value" style="width: 100px" class="curPointer" @click="handlePictureCardPreview" />
    <el-upload
    class="upload-demo"
      action="/shop/fileservice/uploadFile"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-success="handleAvatarSuccess"
      :headers="token"
      :data="{ moduleType: moduleType }"
      :file-list="fileList"
    >
      <el-button size="small" type="primary">上传文件</el-button>
      <!-- <i class="el-icon-plus avatar-uploader-icon"></i> -->
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" size="tiny" :append-to-body="true">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from "@/util/auth";
export default {
  data() {
    return {
      imgSrc: "",
      dialogImageUrl: "",
      dialogVisible: false,
      token: {},
      fileList: [],
    };
  },
  props: {
    value: String,
    moduleType: {
      type: String,
      default: () => {
        return "01";
      },
    },
  },
  mounted() {
    this.token = {
      "Blade-Auth": "bearer " + getToken(),
    };
  },
  methods: {
    // 图片
    handleAvatarSuccess(res) {
      this.imgSrc = res.data[0].filePath;
    },
    // 上传前判断
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    // 移除
    handleRemove() {
      this.imgSrc = "";
    },
    // 预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = this.value;
      this.dialogVisible = true;
    },
  },

  watch: {
    value(val) {
      console.log(val);
      this.imgSrc = val;
    },
    imgSrc(imgSrc) {
      this.$emit("input", imgSrc);
    },
  },
};
</script>

<style lang="scss">
  .upload-demo {
    .el-upload {
      border: none!important;
    }
  }
</style>