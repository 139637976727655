<!--
 * @Author: Jerry
 * @Date: 2021-02-24 14:22:44
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-15 00:40:48
 * @Description: 填写订单号
-->
<template>
  <div>
    <el-form v-if="freightType === 1" label-width="80px">
      <el-form-item label="订单号" v-for="(item, index) in list" :key="index">
        <el-input
            type="text"
            v-model="item.waybillNumber"
            autocomplete="off"
            size="small"
            style="width: 250px; margin-right: 10px"
        ></el-input>
        <el-button type="danger" size="small" @click="delFrom(index)"
        >删除</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="addFrom()" size="mini"
        >新增</el-button
        >
      </el-form-item>
    </el-form>
    <div v-else>
      <div style="margin-bottom: 10px">
        <logisticsUpload moduleType="05" :onChange="getImgFn" btnTitle="批量上传发货单" :isDisabled="isOpen"/>
      </div>
      <div>
        <el-checkbox v-model="isCheck" @change="onChangeCheck"/> 全选
      </div>
      <el-form label-width="80px"
               label-position="right"
               :inline="true">
        <div v-for="(item, index) in list" :key="index">
          <el-checkbox v-model="item.check" @change="onChangeCheckbox"/>
          <el-form-item label="订单号" style="display: inline-block; margin-left: -22px;">
            <el-input
                type="text"
                v-model="item.waybillNumber"
                autocomplete="off"
                size="small"
                style="width: 250px; margin-right: 10px"
            ></el-input>

          </el-form-item>

          <el-form-item label="发货单：" style="display: inline-block; ">
            <span v-if="!item.dispatchBill">暂无发货单</span>

            <div v-else>
            <el-button
                type="text"
                size="mini"
                v-model="item.dispatchBill"
                @click="filePreview(item.dispatchBill)"
                v-if="judgeFileType(item.dispatchBill) == 'pdf' && item.dispatchBill"
            >查看发货单</el-button>
            <el-image
                v-if="judgeFileType(item.dispatchBill) == 'image' && item.dispatchBill"
                :src="item.dispatchBill"
                v-model="item.dispatchBill"
                style="width: 100px"
                :preview-src-list="[ item.dispatchBill ]"
                :z-index="9999999"
            />
            </div>
          </el-form-item>
          <el-button type="danger" size="small" @click="delFrom(index)"
          >删除</el-button
          >
        </div>
        <el-form-item>
          <el-button type="primary" @click="addFrom()" size="mini"
          >新增</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import logisticsUpload from "../upload/logisticsUploadBatch";
  export default {
    components: { logisticsUpload },
    data() {
      return {
        list: [
          {
            waybillNumber: "",
            dispatchBill: ''
          },
        ],
        isOpen: true,
        isCheck: false
      };
    },
    props: {
      freightType: {
        type: Number,
        default: () => {
          return "";
        },
      },
      isEdit: {
        type: Boolean,
        default: false
      },
    },
    methods: {
      clearFrom() {
        this.$set(this, "list", []);
        this.addFrom();
      },
      addFrom() {
        this.list.push({
          waybillNumber: "",
          dispatchBill: ''
        });
      },
      delFrom(index) {
        this.list.splice(index, 1);
      },
      getData() {
        let list = this.list;
        this.list.forEach((res) => {
          res.freightType = this.freightType;
        });
        return list;
      },

      isDispatchBillTip(){
        // 没有完全上传发货单进行警告提示
        this.$confirm(
          "发货单上传的不完整，点击确定继续保存，点击取消前去上传补充！",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.$emit("passEvent");
          })
          .catch(() => {
          });
      },
      checkData() {
        if (this.list.length == 0 && !this.isEdit) {
          this.$message({
            type: "error",
            message: "请至少填写一条数据",
          });
          return false;
        }
        for (let x = 0; x < this.list.length; x++) {
          let res = this.list[x];
          if (res.waybillNumber !== "") {
            if(res.dispatchBill === '' && this.freightType !== 1) {
              this.isDispatchBillTip()
            } else {
              return true
            }
          } else if(res.waybillNumber === "") {
            this.$message({
              type: "error",
              message: "请完整的填写快递单号",
            })
            return false
          }
        }
      },
      // 批量上传发货单
      getImgFn(src){
        if (!src) {
          return
        }
        const newList = JSON.parse(JSON.stringify(this.list))
        newList.map((v) => {
          if(v.check){
            return v.dispatchBill = src
          }
        })
        this.list = newList
        console.log('img', this.list)
      },
      // 是否选中
      onChangeCheckbox(value) {
        const found = this.list.find(element => {
          console.log(1,element.check)
          if (element.check === true) {
            return true
          }
        })
        if (!found) {
          return this.isOpen = true
        }
        this.isOpen = false
      },

      // 校验文件格式
      judgeFileType(fileName) {
        // 后缀获取
        var suffix = "";
        var result = "";
        try {
          var flieArr = fileName.split(".");
          suffix = flieArr[flieArr.length - 1];
        } catch (err) {
          suffix = "";
        }
        // fileName无后缀返回 false
        if (!suffix) {
          result = false;
          return result;
        }
        // 图片格式
        var imglist = ["png", "jpg", "jpeg", "bmp", "gif"];
        // 进行图片匹配
        result = imglist.some(function (item) {
          return item == suffix;
        });
        if (result) {
          result = "image";
          return result;
        }
        // 匹配PDF
        var pdflist = ["pdf"];
        result = pdflist.some(function (item) {
          return item == suffix;
        });
        if (result) {
          result = "pdf";
          return result;
        }
      },
      // 文件预览
      filePreview(url) {
        window.open(url, "_blank");
      },
      onChangeCheck(type){
        console.log('check1',this.list)
        if(this.isCheck){
          return this.list.map((v) => {
            v.check = true
            return this.isOpen = false
          })
        }
        if(!this.isCheck){
          console.log('check2',this.isCheck)
          return this.list.map((v) => {
            v.check = false
            return this.isOpen = true
          })
        }
      }
    },
  };
</script>
