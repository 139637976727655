<template>
  <div>
    <el-dialog :title="'发票文件'" :visible.sync="dialog" width="600px">
      <div>
        <div class="Mbm5">发票代码：{{ InvoiceCode }}</div>
        <div class="Mbm5">发票号码：{{ InvoiceNo }}</div>
        <div class="Mbm5">
          发票文件：
          <el-button
              type="text"
              size="mini"
              v-model="fileUrl"
              @click="filePreview(fileUrl)"
              v-if="judgeFileType(fileUrl) == 'pdf' && fileUrl"
          >查看发票信息</el-button>
          <el-image
              v-if="judgeFileType(fileUrl) == 'image' && fileUrl"
              :src="fileUrl"
              v-model="fileUrl"
              style="width: 150px"
              :preview-src-list="[ fileUrl ]"
              :z-index="9999999"
          />
          <!--<el-image
            style="width: 150px"
            :src="fileUrl"
            :preview-src-list="[ fileUrl ]"
            :z-index="9999"
          >
          </el-image>-->
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModal">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      // 图片地址
      fileUrl: "",
      // 发票代码
      InvoiceCode: "",
      // 发票编码
      InvoiceNo: "",
      id: "",
    };
  },
  mounted() {},
  methods: {
    showModal(data) {
      this.fileUrl = data.fileUrl;
      this.InvoiceCode = data.InvoiceCode;
      this.InvoiceNo = data.InvoiceNo;
      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
      this.fileUrl = "";
      this.InvoiceCode = "";
      this.InvoiceNo = "";
    },


    // 校验文件格式
    judgeFileType(fileName) {
      // 后缀获取
      var suffix = "";
      var result = "";
      try {
        var flieArr = fileName.split(".");
        suffix = flieArr[flieArr.length - 1];
      } catch (err) {
        suffix = "";
      }
      // fileName无后缀返回 false
      if (!suffix) {
        result = false;
        return result;
      }
      // 图片格式
      var imglist = ["png", "jpg", "jpeg", "bmp", "gif"];
      // 进行图片匹配
      result = imglist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = "image";
        return result;
      }
      // 匹配PDF
      var pdflist = ["pdf"];
      result = pdflist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = "pdf";
        return result;
      }
    },
    // 文件预览
    filePreview(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
