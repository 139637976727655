<!--
 * @Author: Jerry
 * @Date: 2021-03-12 15:58:38
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-24 16:58:50
 * @Description: 运单上传
-->

<template>
  <div>
    <el-upload
      class="upload-demo"
      action="/shop/fileservice/uploadFile"
      :disabled="isDisabled"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-success="handleAvatarSuccess"
      :headers="token"
      :data="{ moduleType: moduleType }"
      :file-list="fileList"
      :show-file-list=false>
      <el-button size="small" type="primary" :disabled="isDisabled">{{btnTitle}}</el-button>
    </el-upload>
  </div>
</template>

<script>
import { getToken } from "@/util/auth";
export default {
  data() {
    return {
      imgSrc: "",
      dialogImageUrl: "",
      dialogVisible: false,
      token: {},
      fileList: [],
    };
  },
  props: {
    value: String,
    moduleType: {
      type: String,
      default: () => {
        return "01";
      },
    },
    onChange: {
      type: Function,
      default: () => {
        return
      }
    },
    btnTitle: String,
    isDisabled: Boolean
  },
  mounted() {
    this.token = {
      "Blade-Auth": "bearer " + getToken(),
    };
  },
  methods: {
    // 图片
    handleAvatarSuccess(res) {
      this.imgSrc = res.data[0].filePath;
      this.onChange(res.data[0].filePath)
    },
    // 上传前判断
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    // 移除
    handleRemove() {
      this.imgSrc = "";
    },
    // 预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = this.value;
      this.dialogVisible = true;
    },
  },

  watch: {
    value(val) {
      console.log(val);
      this.imgSrc = val;
    },
    imgSrc(imgSrc) {
      this.$emit("input", imgSrc);
    },
  },
};
</script>

<style lang="scss">
  .upload-demo {
    .el-upload {
      border: none!important;
    }
  }
</style>
