<template>
  <div class="orderoperation Ptp15 Pbm20 Prt15">
    <div class="operation">
      <div class="column5">
        <div class="co_green">
          <span class="orange">{{ matchingOrderStatus(orderState) }}</span>
        </div>
        <div class="btn">
          <el-link @click.stop="orderDetail()">订单详情</el-link>
        </div>
        <div class="btn">
          <el-link @click.stop="seeContract()">查看合同</el-link>
        </div>
        <div class="btn">
          <el-link @click.stop="logisticsInfo()">物流信息</el-link>
        </div>
        <div
          class="btn"
          v-if="orderInfo.sellerInvoice && userInfo.roleType == '1'"
        >
          <el-link @click.stop="getSellerInvoice(orderInfo.orderId)"
            >发票信息</el-link
          >
        </div>
      </div>
      <div class="column6" style="width: 110px; position: relative">
        <img
          v-if="
            (orderInfo.applyInvoice == '2' && userInfo.roleType == '2') ||
            (orderInfo.applyInvoice == '2' && userInfo.roleType == '1')
          "
          src="/img/icon/openInvoice.png"
          style="width: 60px; position: absolute; top: -55px; right: 0px"
        />
        <div
          class="Mbm5"
          v-if="$route.name == 'AllOrders' && userInfo.roleType == '1'"
        >
          <el-button
            v-if="orderInfo"
            type="primary"
            size="mini"
            @click="upLoadInvoice(orderInfo.orderId)"
            >上传发票</el-button
          >
        </div>
        <!--订单取消 删除按钮-->
        <div
          class="Mbm5"
          v-if="$route.name !== 'OrderDetermine' && orderState === '2'"
        >
          <el-button
            type="primary"
            size="mini"
            style="margin: 5px 0 0 0"
            @click="deleteOrder(orderInfo.roleType, orderInfo.orderId)"
          >
            删除订单
          </el-button>
        </div>

        <div
          class="Mbm5"
          v-if="
            $route.name != 'OrderDetermine' &&
            orderState != '1' &&
            orderState != '2'
          "
        >
          <el-button
            type="danger"
            size="mini"
            v-if="
              userInfo.roleType == '2' &&
              (orderInfo.dealCancelStatus == '' ||
                orderInfo.dealCancelStatus == '2')
            "
            @click="buyerOrderRefund()"
            >取消交易</el-button
          >
          <br
            v-if="
              userInfo.roleType == '2' &&
              (orderInfo.dealCancelStatus == '' ||
                orderInfo.dealCancelStatus == '2')
            "
          />
          <span
            class="orange curPointer"
            @click="getAuditResultByDataId(orderInfo, '4')"
          >
            {{ $matching(orderInfo.dealCancelStatus, dealCancelStatus) }}
          </span>
        </div>
        <!-- OrderDetermine，待确认订单：供应商（订单确认、订单取消），采购商（订单取消） -->
        <div v-if="$route.name == 'OrderDetermine' || orderState == '1'">
          <el-button
            type="success"
            size="mini"
            @click="buyerAffirmOrder()"
            v-if="userInfo.roleType == '1'"
            >订单确认</el-button
          >
          <el-button
            type="danger"
            size="mini"
            style="margin: 5px 0 0 0"
            @click="buyerCancelOrder"
            >订单取消</el-button
          >
        </div>
        <!-- WaitPayment，待付款订单：供应商（无需操作，仅查看），采购商（付款申请） -->
        <div v-if="$route.name == 'WaitPayment'">
          <el-button
            type="primary"
            size="mini"
            v-if="userInfo.roleType == '2'"
            @click="payment()"
            >付款</el-button
          >
        </div>
        <!-- WaitSendGoods，待发货订单：
        供应商（自提仅需上传发货单，送货上门需要上传发货单和车辆信息），
        采购商（自提需要上传收货单和车牌信息，送货上门需要上传收货单） -->
        <div v-if="$route.name == 'WaitSendGoods'">
          <el-button
            type="primary"
            size="mini"
            v-if="userInfo.roleType == '1'"
            @click="deliverGoods()"
            >填写货运信息</el-button
          >
        </div>
        <!-- WaitReceived，待收货订单：供应商（无需操作，仅查看），采购商（收获申请） -->
        <div v-if="$route.name == 'WaitReceived'">
          <el-button
            type="primary"
            size="mini"
            v-if="
              userInfo.roleType == '2' &&
              orderInfo.orderStatus != '5' &&
              orderInfo.orderStatus != '7'
            "
            @click="affirmGetOrder()"
            >确认收货</el-button
          >
          <el-link
            size="mini"
            type="text"
            class="Mtp5"
            v-if="orderInfo.orderStatus == '6' && userInfo.roleType == '2'"
            @click="getAuditResultByDataId(orderInfo, '7')"
            >查看失败原因</el-link
          >
          <el-button
            type="primary"
            size="mini"
            v-if="
              userInfo.roleType == '1' &&
              orderInfo.orderStatus != '5' &&
              orderInfo.orderStatus != '7'
            "
            @click="editShipInfo()"
            >修改货运信息</el-button
          >
        </div>
        <!-- HaveSettled：已结单 -->
        <div v-if="$route.name == 'HaveSettled'"></div>
        <div v-if="$route.name == 'TransactionCancel'">
          <el-button
            type="primary"
            size="mini"
            v-if="userInfo.roleType == '2'"
            @click="reStartOrder(orderInfo)"
            >重新下单</el-button
          >
          <div
            class="F12 co_f00 Mtp5"
            v-if="
              userInfo.roleType == '1' &&
              orderInfo.supplierDealCancelStatus == '2'
            "
          >
            不同意交易取消
          </div>
          <div
            class="F12 co_green Mtp5"
            v-if="
              userInfo.roleType == '1' &&
              orderInfo.supplierDealCancelStatus == '3'
            "
          >
            同意交易取消
          </div>
          <div
            class="F12 co_999 Mtp5"
            v-if="
              userInfo.roleType == '1' &&
              orderInfo.supplierDealCancelStatus != '1'
            "
          >
            理由：{{ orderInfo.supplierDealCancelReason }}
          </div>
          <el-tooltip
            class="item"
            effect="dark"
            :content="orderInfo.dealCancelReason"
            placement="top-start"
          >
            <div
              class="F12 co_999 Mbm5"
              v-if="
                userInfo.roleType == '1' &&
                orderInfo.supplierDealCancelStatus == '1'
              "
            >
              查看申请原因
            </div>
          </el-tooltip>
          <el-button
            type="success"
            size="mini"
            v-if="
              userInfo.roleType == '1' &&
              orderInfo.supplierDealCancelStatus == '1'
            "
            @click="buyerDealCancel('1', orderInfo.orderId)"
            >同意取消</el-button
          >
          <el-button
            type="danger"
            size="mini"
            style="margin: 5px 0 0 0"
            v-if="
              userInfo.roleType == '1' &&
              orderInfo.supplierDealCancelStatus == '1'
            "
            @click="buyerDealCancel('2', orderInfo.orderId)"
            >不同意取消</el-button
          >
          <el-button
            v-if="
              userInfo.roleType === '1' || orderInfo.dealCancelStatus == '3'
            "
            type="primary"
            size="mini"
            style="margin: 5px 0 0 0"
            @click="deleteOrder(orderInfo.roleType, orderInfo.orderId)"
          >
            删除订单
          </el-button>
        </div>
      </div>
    </div>

    <el-dialog
      v-if="confDialog.dialogVisible"
      title="审核信息"
      :visible.sync="confDialog.dialogVisible"
      width="30%"
    >
      系统审核：{{ confDialog.auditReason }} <br />
      供应商审核：{{ confDialog.supplierDealCancelReason }}
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confDialog.dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 上传发票 -->
    <upLoadInvoice
      ref="upLoadInvoice"
      @submit="uploadSellerInvoice"
    ></upLoadInvoice>
    <!-- 查看发票信息 -->
    <viewInvoice ref="viewInvoice"></viewInvoice>
  </div>
</template>
<script>
import { matchingOrderStatus, dealCancelStatus } from "@/util/util";
import {
  buyerAffirmOrder,
  buyerCancelOrder,
  buyerCancelOrderBuyer,
  uploadSellerInvoice,
  getSellerInvoice,
} from "@/api/user";

import {
  getAuditResultByDataId,
  buyerOrderDelete,
  sellOrderDelete,
  deleteSellerCancelOrder,
  deleteBuyerCancelOrder,
} from "@/api/order";

import { mapGetters } from "vuex";
import upLoadInvoice from "@/components/upLoadInvoice";
import viewInvoice from "@/components/invoice/viewInvoice";
export default {
  components: { upLoadInvoice, viewInvoice },
  name: "OrderOperation",
  props: {
    orderState: {
      type: String,
      default: "default",
    },
    orderInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    name: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      dealCancelStatus,
      isHaveGoods: "",
      // viewContract: false,
      confDialog: {
        dialogVisible: false,
        auditReason: null,
        supplierDealCancelReason: null,
      },
    };
  },
  methods: {
    // 刷新列表
    refreshData() {
      this.$emit("refreshData");
    },
    sendGoods() {
      this.$router.push("/supplier/supplierHome/waitDelivery");
    },
    matchingOrderStatus: matchingOrderStatus,
    // 卖家确认订单
    buyerAffirmOrder() {
      buyerAffirmOrder(this.orderInfo.orderId).then((res) => {
        if (res.success) {
          this.$message({
            type: "success",
            message: "订单确认成功！",
          });
          this.refreshData();
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    // 卖家取消订单
    buyerCancelOrder() {
      let request = buyerCancelOrder;
      if (this.userInfo.roleType == "2") {
        request = buyerCancelOrderBuyer;
      }
      request(this.orderInfo.orderId).then((res) => {
        if (res.success) {
          this.$message({
            type: "success",
            message: "订单已取消",
          });
          this.refreshData();
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    // 发货
    deliverGoods() {
      this.$emit("deliverGoods", this.orderInfo);
    },
    // 查看物流
    logisticsInfo() {
      this.$emit("logisticsInfo", this.orderInfo);
    },
    // 确认收货
    affirmGetOrder() {
      this.$emit("affirmGetOrder", this.orderInfo);
    },
    // 付款
    payment() {
      this.$emit("payment", this.orderInfo);
    },
    // 申请开票
    orderOpenInvoiceApply() {
      this.$emit("orderOpenInvoiceApply", this.orderInfo.orderId);
    },
    // 申请退款
    buyerOrderRefund() {
      // this.$emit("buyerOrderRefund", this.orderInfo);
      this.$router.push({
        name: "OrderRefundModal",
        query: {
          orderId: this.orderInfo.orderId,
        },
      });
    },
    // 订单详情
    orderDetail() {
      this.$router.push({
        name: "OrderDetail",
        query: {
          orderId: this.orderInfo.orderId,
        },
      });
    },
    //查看合同
    seeContract() {
      // 供应商：1；采购商：2
      if (this.userInfo.roleType == "1") {
        this.$emit("seeContract", this.orderInfo.sellContract);
      } else if (this.userInfo.roleType == "2") {
        this.$emit("seeContract", this.orderInfo.buyerContract);
      }
      // this.viewContract = true;
      // this.$refs.contract.showDialog()
    },
    // 重新申请退款
    againReapply() {
      this.$emit("againReapply", this.orderInfo);
    },
    // 查看收货审核失败原因
    getAuditResultByDataId(orderId, moudleType) {
      getAuditResultByDataId({
        auditDataId: orderId.orderId,
        moudleType,
      }).then((res) => {
        if (res.success) {
          this.confDialog.auditReason = res.data.auditReason;
          this.confDialog.supplierDealCancelReason =
            orderId.supplierDealCancelReason;
          this.confDialog.dialogVisible = true;

          // this.$alert(`系统审核：${res.data.auditReason}供应商审核：${orderId.supplierDealCancelReason}`, "审核理由", {
          //   confirmButtonText: "确定",
          // });
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    // 重新下单
    reStartOrder(orderInfo) {
      this.$router.push({
        name: "OrderSettlement",
        query: {
          type: "goodsDetail",
          ids: orderInfo.goodsId,
          goodsSellId: orderInfo.goodsSellId,
          number: orderInfo.goodsCount,
        },
      });
    },
    // 供应商确认交易取消
    // affirmStatus：供应商确认状态(1：同意，2：不同意)
    buyerDealCancel(affirmStatus, orderId) {
      this.$emit("buyerDealCancel", { affirmStatus, orderId });
    },
    // 删除订单
    // roleType： 1、供应商；2、采购商
    deleteOrder(roleType, orderId) {
      this.$confirm("是否删除订单?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let method =
            this.userInfo.roleType === "2"
              ? deleteBuyerCancelOrder
              : deleteSellerCancelOrder;
          method({
            orderId,
          }).then((res) => {
            if (res.success) {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.refreshData();
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {});
    },
    // 打开上传发票的弹窗
    upLoadInvoice(orderId) {
      this.$refs.upLoadInvoice.showModal(orderId);
    },
    // 发票上传
    uploadSellerInvoice(fileData) {
      uploadSellerInvoice(fileData).then((res) => {
        if (res.success) {
          this.$refs.upLoadInvoice.closeModal();
          // this.refreshChange();
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    // 获取卖家发票信息
    getSellerInvoice(orderId) {
      getSellerInvoice({ orderId }).then((res) => {
        if (res.success) {
          // 打开弹窗
          this.$refs.viewInvoice.showModal({
            fileUrl: res.data.sellerInvoice,
            InvoiceCode: res.data.sellerInvoiceCode,
            InvoiceNo: res.data.sellerInvoiceNo,
          });
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    // 编辑发货信息
    editShipInfo() {
      this.$emit("editShipInfo", this.orderInfo);
    },
  },
};
</script>
<style lang="scss" scoped>
.operation {
  display: flex;
  justify-content: space-between;
}
.column5,
.column6 {
  .btn {
    // margin-top: 3px;
  }
  .sendgoods {
    padding: 2px 7px;
    color: #fff;
  }
  .fapiao {
    padding: 2px 12px;
    color: #fff;
  }
}
</style>
