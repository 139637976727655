<template>
  <div class="goodstitle bg_f4f4 Ptp15 Pbm15 Plf20 Mtp10">
    <slot name="title1"></slot>
    <div class="Mlf40">
      <slot name="title2"></slot>
    </div>
    <div class="Mlf80">
      <slot name="title3"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "GoodsTitle",
};
</script>
<style lang="scss" scoped>
.goodstitle {
  div {
    display: inline-block;
  }
}
</style>
