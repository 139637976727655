<template>
  <div class="orderlists">
    <div v-for="(item, index) in dataSource" :key="index">
      <goods-title>
        <span slot="title1"> {{ formatDate(item.orderTime * 1000) }} </span>
        <div slot="title2">
          <span> 订单号: </span><span>{{ item.orderNo }}</span>
        </div>
        <span slot="title3"></span>
      </goods-title>
      <div class="wrap">
        <div class="checkbox Ptp35 Plf20" v-show="hasCheckbox">
          <el-checkbox
            v-model="item.checked"
            @change="changeCheckAll()"
          ></el-checkbox>
        </div>
        <div class="goodsdetail">
          <goods-order>
            <img
              slot="img"
              :src="item.goodsLogo"
              class="curPointer"
              @click="goGoodsDetail(item.goodsId)"
            />
            <div slot="goodsdetail" class="F12">
              <div class="Mbm5 curPointer" @click="goGoodsDetail(item.goodsId)">
                {{ item.goodsTitle }}
              </div>
              <div>规格：{{ item.goodsSellName }}</div>
            </div>
            <span slot="column2">{{ item.goodsCount }}</span>
            <span slot="column3">{{
              item.delivery == "1" ? "送货上门" : "自提"
            }}</span>
            <span slot="column4" style="text-indent: 10px">{{
              item.orderMoney
            }}</span>
          </goods-order>
        </div>
        <div class="goodsopear">
          <order-operation
            :orderInfo="item"
            :orderState="item.orderStatus"
            :name="name"
            @refreshData="refreshData"
            @deliverGoods="deliverGoods"
            @logisticsInfo="logisticsInfo"
            @affirmGetOrder="affirmGetOrder"
            @payment="payment"
            @orderOpenInvoiceApply="orderOpenInvoiceApply"
            @seeContract="seeContract"
            @againReapply="againReapply"
            @buyerDealCancel="buyerDealCancel"
            @editShipInfo="editShipInfo"
          ></order-operation>
          <!-- @buyerOrderRefund="buyerOrderRefund" -->
        </div>
      </div>
    </div>

    <!-- 发货 -->
    <deliverGoods ref="deliverGoods" @refreshData="refreshData"></deliverGoods>
    <!-- 物流信息 -->
    <logisticsList ref="logisticsList"></logisticsList>
    <!-- 订单退款 -->
    <!-- <orderRefundModal
      ref="orderRefundModal"
      @success="refundSuccess"
    ></orderRefundModal> -->
    <!-- 查看合同 -->
    <view-contract ref="contract"></view-contract>

    <!-- 确认收货补齐信息 -->
    <affirmGetOrder
      ref="affirmGetOrder"
      @refreshData="refreshData"

    ></affirmGetOrder>

    <vue-pay
      ref="vuePay"
      :dialogShow="flag"
      @closeDialog="payMoney"
      title="支付"
    ></vue-pay>

    <!-- 修改发货信息 -->
    <editShipInfo ref="editShipInfo" @refreshData="refreshData"></editShipInfo>
  </div>
</template>
<script>
import GoodsOrder from "./goodsOrder.vue";
import GoodsTitle from "./goodsTitle.vue";
import OrderOperation from "./orderOperation.vue";
import deliverGoods from "./deliverGoods";
// import logisticsInfo from "./logisticsInfo";
import logisticsList from "./logisticsList";
// import orderRefundModal from "./orderRefundModal";
import { formatDate } from "@/util/util";

import { buyerPaymentOrder, orderOpenInvoiceApply } from "@/api/product";
import { refundAgainSubmitAuditData } from "@/api/product";
import { buyerDealCancel } from "@/api/order";
import { mapGetters } from "vuex";
import ViewContract from "../../views/purchaser/personalCenter/order/ViewContract.vue";
import affirmGetOrder from "./affirmGetOrder.vue";
import VuePay from "@/components/supplier/vuePay.vue";
import editShipInfo from "@/components/myOrder/editShipInfo.vue";

export default {
  components: {
    GoodsOrder,
    GoodsTitle,
    OrderOperation,
    deliverGoods,
    logisticsList,
    ViewContract,
    affirmGetOrder,
    // logisticsInfo,
    // orderRefundModal,
    VuePay,
    editShipInfo,
  },
  props: {
    dataSource: {
      type: Array,
      default: () => {
        return [];
      },
    },
    hasCheckbox: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      checkedAll: false,
      flag: false,
      password: "",
      item: {},
    };
  },
  methods: {
    refreshData() {
      this.$emit("refreshData");
    },
    changeCheckAll() {
      let len = this.dataSource.length;
      for (let i = 0; i < len; i++) {
        if (this.dataSource[i].checked == false) {
          this.checkedAll = false;
          break;
        } else {
          this.checkedAll = true;
        }
      }
      this.$emit("changeCheckAll", this.checkedAll);
    },
    // 发货
    deliverGoods(item) {
      this.$refs.deliverGoods.showModal(item);
    },
    // 查看物流
    logisticsInfo(item) {
      // this.$refs.logisticsInfo.showModal(item);
      this.$refs.logisticsList.showModal(item);
    },
    // 确认收货
    affirmGetOrder(item) {
      item.isSHow="1"
      this.$refs.affirmGetOrder.showModal(item);
    },
    // 付款
    payment(item) {
      this.$store.dispatch("GetLoginUserInfoByToken").then(() => {
        if (this.userInfo.balance < Number(item.orderMoney)) {
          this.$message.error("账户余额不足，请前往我的账户进行充值！");
          return false;
        }
        if (this.userInfo.withdrawalPwd == "") {
          this.$confirm("您还未设置支付密码，请前往设置！", "提示", {
            confirmButtonText: "前往设置",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$router.push(
                "/supplier/supplierHome/depositPwdChange?redirect=" +
                  this.$route.name
              );
            })
            .catch(() => {});
          return false;
        }
        this.$set(this, "item", item);
        this.flag = true;
      });
    },
    //支付密码
    payMoney(val) {
      this.flag = false;
      if (val) {
        this.password = val;
        buyerPaymentOrder({
          orderId: this.item.orderId,
        }).then((res) => {
          if (res.success) {
            this.$message.success(res.msg);
            this.refreshData();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    // 开票申请
    orderOpenInvoiceApply(orderId) {
      this.$confirm("是否将选中的数据提交开票申请？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          orderOpenInvoiceApply({
            orderId: orderId,
          }).then((res) => {
            if (res.success) {
              this.$message.success(res.msg);
              this.refreshData();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    // 订单退款
    // buyerOrderRefund(row) {
    // this.$refs.orderRefundModal.showModal(row.orderId);
    // },
    // refundSuccess(data) {
    //   buyerOrderRefund(data).then((res) => {
    //     if (res.success) {
    //       this.refreshData();
    //       this.$refs.orderRefundModal.closeModal();
    //       this.$message.success("已提交退款申请！");
    //     } else {
    //       this.$message.error(res.msg);
    //     }
    //   });
    // },
    // 获取选中的数据
    getCheckData() {
      let checkData = [];
      for (let x = 0; x < this.dataSource.length; x++) {
        let result = this.dataSource[x];
        if (result.checked) {
          checkData.push(result.orderId);
        }
      }
      return checkData.join(",");
    },
    //查看合同
    seeContract(contract) {
      this.$refs.contract.showDialog(contract);
    },
    // 重新申请退款
    againReapply(data) {
      refundAgainSubmitAuditData({ orderId: data.orderId }).then((res) => {
        if (res.success) {
          this.$message.success(res.msg);
          this.refreshData();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    buyerDealCancel(data) {
      this.$prompt("请输入理由", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValidator: this.inputValidator,
        inputErrorMessage: "请输入理由",
      })
        .then(({ value }) => {
          buyerDealCancel({
            affirmStatus: data.affirmStatus,
            dealCancelReason: value,
            orderId: data.orderId,
          }).then((res) => {
            if (res.success) {
              this.$message.success(res.msg);
              this.refreshData();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    inputValidator(value) {
      if (this.validatenull(value)) {
        return false;
      } else {
        return true;
      }
    },
    goGoodsDetail(id) {
      window.open("/gooddetail?id=" + id);
    },
    // 编辑发货信息
    editShipInfo(orderInfo) {
      this.$refs.editShipInfo.showModal(orderInfo);
    },
    formatDate: formatDate,
  },
};
</script>
<style lang="scss" scoped>
.orderall .wrap {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f4f4f4;
  border-left: 1px solid #f4f4f4;
  border-right: 1px solid #f4f4f4;
  .checkbox {
    height: 21px;
    width: 15px;
    box-sizing: border-box;
  }
  .goodsopear {
    width: 240px;
  }
  .goodsdetail {
    width: 750px;
    img {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
