<template>
  <div>
    <el-dialog
        title="修改货运信息"
        :visible.sync="dialogVisible"
        width="1000px"
        top="5vh"
        :append-to-body="true"
    >
      <div>
        <div>
          <logisticsTable
              ref="logisticsTable"
              :tableData="tableData"
              :type="freightType"
              :isEdit="true"
          ></logisticsTable>
        </div>
        <!-- 网络货运平台、快递 -->
        <deliverOrderNumber
            v-if="freightType == 1 || freightType == 3"
            ref="deliverOrderNumber"
            class="deliverOrderNumber"
            :freightType="Number(freightType)"
            :isEdit="true"
            @passEvent="deliverOrderLogisticsEvent"
        ></deliverOrderNumber>
        <!-- 第三方物流 -->
        <thirdPartyLogistics
            v-if="freightType == 2 || freightType == 4"
            ref="thirdPartyLogistics"
            :delivery="orderInfo.delivery"
            :isEdit="true"
            @passEvent="thirdPartyLogisticsEvent"
        ></thirdPartyLogistics>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import deliverOrderNumber from "./deliverOrderNumber";
  import thirdPartyLogistics from "./thirdPartyLogistics";
  import logisticsTable from "./logisticsTable";
  import { findLogisticsList, supplementShipments } from "@/api/product";
  export default {
    data() {
      return {
        dialogVisible: false,
        freightType: null,
        tableData: [],
        orderInfo: {},
      };
    },
    components: { deliverOrderNumber, thirdPartyLogistics, logisticsTable },
    methods: {
      // 打开模态框
      showModal(orderInfo) {
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$set(this, "orderInfo", orderInfo);
          this.findLogisticsList(orderInfo.orderId);
        })
      },
      findLogisticsList(orderId) {
        findLogisticsList({
          orderId: orderId,
        }).then((res) => {
          if (res.success) {
            if (res.data.length > 0) {
              this.freightType = res.data[0].freightType;
              this.$set(this, "tableData", this.formatTableData(res.data));
              this.$refs.thirdPartyLogistics ? this.$refs.thirdPartyLogistics.list = [] : '';
              this.$refs.deliverOrderNumber ? this.$refs.deliverOrderNumber.list = [] : '';
            }
          }
        });
      },

      // 格式化物流信息表格数据
      formatTableData(value){
        const arr = []
        value.map((v, i)=>{
          if(!v.waybillNumber) {return}
          if (!v.webTransportNo) {
            return arr.push(v)
          }
          let s1 = v.webTransportNo.split(',');
          s1.forEach((item,index)=>{
            let obj = {};
            obj.transportNo = item;
            obj.id = v.id
            obj.orderId = v.orderId
            obj.logisticsCompany = v.logisticsCompany
            obj.logisticsLogo = v.logisticsLogo
            obj.logisticsStatus = v.logisticsStatus
            obj.freightType = v.freightType
            obj.waybillNumber = v.waybillNumber
            obj.dispatchBill = v.dispatchBill
            obj.receivingNote = v.receivingNote
            obj.plateNumber = v.plateNumber
            obj.freightTimeStart = v.freightTimeStart
            obj.freightTimeEnd = v.freightTimeEnd
            obj.remark = v.remark
            obj.dataSort = v.dataSort
            obj.createUser = v.createUser
            obj.createDept = v.createDept
            obj.createTime = v.createTime
            obj.updateUser = v.updateUser
            obj.updateTime = v.updateTime
            obj.status = v.status
            obj.isDeleted = v.isDeleted
            obj.webOrderNum = v.webOrderNum
            obj.webFreightUnit = v.webFreightUnit
            obj.webOrderStatus = v.webOrderStatus
            obj.goodsName = v.goodsName
            obj.freightWeight = v.freightWeight
            return arr.push(obj)
          })
        })

        console.log('item-',arr)
        return arr
      },
      submit() {
        if (this.freightType == 1 || this.freightType == 3) {
          if (this.$refs.deliverOrderNumber.checkData()) {
            this.deliverOrderLogisticsEvent()
          }
        } else if (this.freightType == 2) {
          if (this.$refs.thirdPartyLogistics.checkData()) {
            this.thirdPartyLogisticsEvent();
          }
        } else if (this.freightType == 4) {
          console.log(this.freightType);
        }
      },
      // 快递的子组件回调方法
      deliverOrderLogisticsEvent(){
        let logisticsDTO = {
          logisticsList: this.tableData.concat(this.$refs.deliverOrderNumber.getData()),
          orderId: this.orderInfo.orderId,
        };
        this.sellerShipments(logisticsDTO);
      },
      // 第三方物流的子组件回调方法
      thirdPartyLogisticsEvent() {
        
        let logisticsDTO = {
          logisticsList: this.tableData.concat(this.$refs.thirdPartyLogistics.getData()),
          orderId: this.orderInfo.orderId,
        };
        
        this.sellerShipments(logisticsDTO);
      },
      // 卖家发货
      sellerShipments(data) {
        
        supplementShipments(data).then((res) => {
          if (res.success) {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.dialogVisible = false;
            this.$emit("refreshData");
          } else {
            this.$message({
              type: "danger",
              message: res.msg,
            });
          }
        });
      },
    },
  };
</script>
