<!--
 * @Author: Jerry
 * @Date: 2021-03-24 16:00:42
 * @LastEditors: Jerry
 * @LastEditTime: 2021-04-09 17:55:23
 * @Description: 发货信息列表
-->
<template>
  <div>
    <el-dialog
      title="货运信息"
      :visible.sync="dialogVisible"
      width="1000px"
      :append-to-body="true"
    >
      <div style="padding-bottom: 10px">
        <!-- 采购商  自提 才需要导入、导出运单 -->
        <el-button
          type="primary"
          size="small"
          style="display: inline-block; margin-right: 10px"
          v-if="delivery === 2"
          @click="handleExportXls('货运信息')"
          >导出全部运单</el-button
        >
        <el-upload
          style="display: inline-block; margin-left: 10px"
          action="https://jsonplaceholder.typicode.com/posts/"
          :on-change="exportData"
          :auto-upload="false"
          :show-file-list="false"
          v-if="delivery === 2"
        >
          <el-button type="primary" size="small">excel导入运单</el-button>
        </el-upload>
        <!-- 采购商 送货上门、自提 才需要上传收货单 -->
        <div style="display: inline-block; margin-left: 10px">
          <Batch
            moduleType="05"
            :onChange="getImgFn"
            btnTitle="批量上传收货单"
            :isDisabled="isOpen"
          />
        </div>
      </div>
      <div style="max-height: 500px; overflow: auto">
        <el-table
          v-if="type == '1' || type == '2' || type == '4'"
          :data="tableData"
          style="width: 100%"
          border
          size="mini"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="freightType" label="货运类型" width="90">
            <template slot-scope="scope">
              {{ freightType[scope.row.freightType] }}
            </template>
          </el-table-column>
          <el-table-column prop="waybillNumber" label="运单号">
          </el-table-column>
          <el-table-column prop="dispatchBill" label="发货单" width="130px">
            <template slot-scope="scope">
              <span v-if="!scope.row.dispatchBill">暂无发货单</span>
              <div v-else>
                <el-button
                  type="text"
                  size="mini"
                  @click="filePreview(scope.row.dispatchBill)"
                  v-if="
                    judgeFileType(scope.row.dispatchBill) == 'pdf' &&
                    scope.row.dispatchBill
                  "
                  >查看发货单</el-button
                >
                <el-image
                  :src="scope.row.dispatchBill"
                  style="width: 100px"
                  :preview-src-list="[scope.row.dispatchBill]"
                  v-if="
                    judgeFileType(scope.row.dispatchBill) == 'image' &&
                    scope.row.dispatchBill
                  "
                  :z-index="99999"
                ></el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="receivingNote" label="收货单" width="130px">
            <template slot-scope="scope">
              <span v-if="!scope.row.receivingNote">暂无收货单</span>
              <div v-else>
                <el-button
                  type="text"
                  size="mini"
                  @click="filePreview(scope.row.receivingNote)"
                  v-if="
                    judgeFileType(scope.row.receivingNote) == 'pdf' &&
                    scope.row.receivingNote
                  "
                  >查看收货单</el-button
                >
                <el-image
                  :src="scope.row.receivingNote"
                  style="width: 100px"
                  :z-index="99999"
                  :preview-src-list="[scope.row.receivingNote]"
                  v-if="
                    judgeFileType(scope.row.receivingNote) == 'image' &&
                    scope.row.receivingNote
                  "
                ></el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="plateNumber" label="车牌信息">
          </el-table-column>
          <el-table-column prop="carColor" label="车牌颜色">
            <template slot-scope="scope">
              <span v-if="!scope.row.carColor">无</span>
              <div v-else>
                <span>{{ scope.row.carColor }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="goodsName" label="商品名称"></el-table-column>
          <el-table-column
            prop="freightWeight"
            label="商品数量"
          ></el-table-column>
          <el-table-column
            prop="freightTimeStart"
            label="发货时间"
            width="90px"
          >
            <template slot-scope="scope">
              {{ $formatDate(scope.row.freightTimeStart * 1000) }}
            </template>
          </el-table-column>
          <el-table-column prop="freightTimeEnd" label="收货时间" width="90px">
            <template slot-scope="scope">
              {{ $formatDate(scope.row.freightTimeEnd * 1000) }}
            </template>
          </el-table-column>
          <!--          <el-table-column prop="action" label="操作">-->
          <!--            <template slot-scope="scope">-->
          <!--              <el-button-->
          <!--                @click="supplement(scope.row)"-->
          <!--                type="primary"-->
          <!--                size="mini"-->
          <!--                >上传收货信息</el-button-->
          <!--              >-->
          <!--            </template>-->
          <!--          </el-table-column>-->
        </el-table>
        <el-table
          v-if="type == '3'"
          :data="tableData"
          style="width: 100%"
          border
          size="mini"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="freightType" label="货运类型" width="120">
            <template slot-scope="scope">
              {{ freightType[scope.row.freightType] }}
            </template>
          </el-table-column>
          <el-table-column prop="waybillNumber" label="运单号">
          </el-table-column>
          <el-table-column prop="dispatchBill" label="发货单" width="130px">
            <template slot-scope="scope">
              <span v-if="!scope.row.dispatchBill">暂无发货单</span>

              <div v-else>
                <el-button
                  type="text"
                  size="mini"
                  @click="filePreview(scope.row.dispatchBill)"
                  v-if="
                    judgeFileType(scope.row.dispatchBill) == 'pdf' &&
                    scope.row.dispatchBill
                  "
                  >查看发货单</el-button
                >
                <el-image
                  :src="scope.row.dispatchBill"
                  style="width: 100px"
                  :preview-src-list="[scope.row.dispatchBill]"
                  v-if="
                    judgeFileType(scope.row.dispatchBill) == 'image' &&
                    scope.row.dispatchBill
                  "
                  :z-index="99999"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="receivingNote" label="收货单" width="130px">
            <template slot-scope="scope">
              <span v-if="!scope.row.receivingNote">暂无收货单</span>

              <div v-else>
                <el-button
                  type="text"
                  size="mini"
                  @click="filePreview(scope.row.receivingNote)"
                  v-if="
                    judgeFileType(scope.row.receivingNote) == 'pdf' &&
                    scope.row.receivingNote
                  "
                  >查看收货单</el-button
                >
                <el-image
                  :src="scope.row.receivingNote"
                  style="width: 100px"
                  :z-index="99999"
                  :preview-src-list="[scope.row.receivingNote]"
                  v-if="
                    judgeFileType(scope.row.receivingNote) == 'image' &&
                    scope.row.receivingNote
                  "
                />
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="affirmGetOrder">确认收货</el-button>
      </span>
    </el-dialog>

    <!-- 上传收货单 -->
    <el-dialog
      title="补充信息"
      :visible.sync="visible"
      width="750px"
      :append-to-body="true"
    >
      <div>
        <el-form label-position="right" label-width="120px">
          <el-form-item label="运单号：">
            {{ basicInfo.waybillNumber }}
          </el-form-item>
          <el-form-item label="发货单：">
            <el-image
              :src="basicInfo.dispatchBill"
              style="width: 200px"
              :preview-src-list="[basicInfo.dispatchBill]"
              :z-index="99999"
            ></el-image>
          </el-form-item>
          <el-form-item label="车牌信息：">
            <el-input v-model="basicInfo.plateNumber"></el-input>
          </el-form-item>
          <el-table-column prop="goodsName" label="商品名称"></el-table-column>
          <el-table-column
            prop="freightWeight"
            label="商品数量"
          ></el-table-column>
          <el-form-item label="发货时间：">
            <el-date-picker
              v-model="basicInfo.freightTimeStart"
              type="datetime"
              placeholder="选择发货时间"
              format="yyyy-MM-dd HH:mm"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="货运结束时间：">
            <el-date-picker
              v-model="basicInfo.freightTimeEnd"
              type="datetime"
              placeholder="选择货运结束时间"
              format="yyyy-MM-dd HH:mm"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="上传收货单：">
            <logisticsUpload
              v-model="basicInfo.receivingNote"
              moduleType="05"
            ></logisticsUpload>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="saveSupplement()">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  findLogisticsList,
  affirmGetOrder,
  sellerShipmentsByBuyer,
  downFile,
  buyerImport,
  sellerImport,
} from "@/api/product";
import { listMixin } from "@/mixins/listMixins";
import Batch from "../upload/logisticsUploadBatch";
import logisticsUpload from "../upload/logisticsUpload";
import { freightType } from "@/util/util";
import XLSX from "xlsx";
import { mapGetters } from "vuex";
import moment from "moment";
import BigNumber from "bignumber.js";
export default {
  mixins: [listMixin],
  components: { logisticsUpload, Batch },
  name: "",
  data() {
    return {
      freightType,
      dialogVisible: false,
      visible: false,
      tableData: [],
      basicInfo: {},
      delivery: 1,
      item: {},
      logisticsStatus: "1",
      type: "1",
      isSHow: null,
      queryParam: {},
      checkId: [],
      isOpen: true,
      logisticsIds: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  watch: {},
  methods: {
    showModal(item) {
      this.item = item;
      // 1：送货上门；2：自提
      this.delivery = item.delivery;
      console.log("1：送货上门；2：自提", this.delivery);
      // 采购商确认收货，如果货运类型是第三方物流，就需要采购商去上传收货单文件
      this.findLogisticsList(this.item);
      this.isSHow = item.isSHow;
      this.queryParam.orderId = item.orderId;
      this.queryParam.goodsSellId = item.goodsSellId;
      this.queryParam.goodsId = item.goodsId;
    },
    findLogisticsList(item) {
      findLogisticsList({
        orderId: item.orderId,
      }).then((res) => {
        if (res.data.length > 0) {
          this.type = res.data[0].freightType;
          this.dialogVisible = true;
          this.$set(this, "tableData", res.data);
        }
      });
    },
    // 上传收货单
    supplement(item) {
      console.log("item", item);
      this.basicInfo = this.deepClone(item);
      this.basicInfo.freightTimeStart =
        this.basicInfo.freightTimeStart == -1
          ? ""
          : this.basicInfo.freightTimeStart * 1000;
      this.basicInfo.freightTimeEnd =
        this.basicInfo.freightTimeEnd == -1
          ? ""
          : this.basicInfo.freightTimeEnd * 1000;
      this.visible = true;
    },

    // 保存收货信息
    saveSupplement() {
      let data = this.deepClone(this.basicInfo);
      if (!this.checkData(data)) {
        return false;
      }
      data.freightTimeStart = data.freightTimeStart / 1000;
      data.freightTimeEnd = data.freightTimeEnd / 1000;
      sellerShipmentsByBuyer(data).then((res) => {
        if (res.success) {
          this.findLogisticsList(this.item);
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.visible = false;
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    affirmGetOrder() {
      var weight = 0;
      var orderWeight = Number(this.item.goodsCount);
      //修改四舍五入
      this.tableData.forEach((item) => {
        weight = Number(
          new BigNumber(weight).plus(item.freightWeight).toFixed(3)
        );
        // weight = weight + item.freightWeight;
      });
      console.log(orderWeight); //订单信息商品数量
      console.log(weight); //货运信息商品数量
      let message = `订单量是${orderWeight}，发货量是${weight}，收货数量不符，是否确认要收货？`;
      //判断发货量是否小于订单量
      if (orderWeight !== weight) {
        this.$confirm(message, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.affirmGetWeightOrder();
          })
          .catch(() => {});
      } else {
        this.affirmGetWeightOrder();
      }
    },
    affirmGetWeightOrder() {
      let isPass = true;
      let isReceivingNote = true;
      let message = "";
      for (let x = 0; x < this.tableData.length; x++) {
        let result = this.tableData[x];
        // 快递，第三方物流增加发货单为空提示语
        if (result.freightType == "2" || result.freightType === "3") {
          if (result.receivingNote.length <= 0) {
            isReceivingNote = false;
            break;
          }
          // 发货单
          if (!result.dispatchBill) {
            isPass = false;
            break;
          }
          // 收货单
          if (!result.receivingNote) {
            isPass = false;
            break;
          }
          // 车牌信息
          if (!result.plateNumber) {
            isPass = false;
            break;
          }
          // 发货时间
          if (!result.freightTimeStart) {
            isPass = false;
            break;
          }
          // 收货时间
          if (!result.freightTimeEnd) {
            isPass = false;
            break;
          }
        }
      }
      // if (!isPass) {
      //   this.$message.warning('请填写完整收货信息再确认收货')
      //   return false
      // }
      if (!isReceivingNote) {
        console.log(2, isReceivingNote);
        message = "未上传收货单，是否确认收到货物？";
      } else {
        console.log(3, isReceivingNote);
        message = "是否确认收到货物？";
      }
      this.$confirm(message, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          affirmGetOrder({
            orderId: this.item.orderId,
          }).then((res) => {
            if (res.success) {
              this.$message.success(res.msg);
              this.dialogVisible = false;
              this.$emit("refreshData");
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },

    checkData(res) {
      if (res.receivingNote == "") {
        this.$message({
          type: "error",
          message: "请上传收货单",
        });
        return false;
      }
      if (!res.plateNumber && this.delivery == 1) {
        this.$message({
          type: "error",
          message: "请完整的填写车牌信息",
        });
        return false;
      }
      if (!res.freightTimeStart && this.delivery == 1) {
        this.$message({
          type: "error",
          message: "请完整的填写发货时间",
        });
        return false;
      }
      if (!res.freightTimeEnd && this.delivery == 1) {
        this.$message({
          type: "error",
          message: "请完整的填写收货时间",
        });
        return false;
      }
      return true;
    },

    // 导出
    handleExportXls(fileName) {
      if (!fileName || typeof fileName != "string") {
        fileName = "导出文件";
      }
      downFile({ orderId: this.queryParam.orderId }).then((data) => {
        if (!data) {
          this.$message.warning("文件下载失败");
          return;
        }
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          window.navigator.msSaveBlob(
            new Blob([data], { type: "application/vnd.ms-excel" }),
            fileName + ".xlsx"
          );
        } else {
          let url = `/shop/logisticsExportController/exportLogistics?orderId=${this.queryParam.orderId}`;
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", fileName + ".xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
        }
      });
    },
    // 导入
    exportData(file) {
      var that = this;
      // 拿取文件对象
      var f = file.raw;
      // 用FileReader来读取
      var reader = new FileReader();
      // 重写FileReader上的readAsBinaryString方法
      FileReader.prototype.readAsBinaryString = function (f) {
        var binary = "";
        var wb; // 读取完成的数据
        var outdata; // 你需要的数据
        var reader = new FileReader();
        reader.onload = function (e) {
          // 读取成Uint8Array，再转换为Unicode编码（Unicode占两个字节）
          var bytes = new Uint8Array(reader.result);
          var length = bytes.byteLength;
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          // 接下来就是xlsx了，具体可看api
          wb = XLSX.read(binary, {
            type: "binary",
          });
          outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
          // 自定义方法向父组件传递数据
          that.exportDataFormat(outdata);
          // 校验获取到的信息是否符合规定
        };
        reader.readAsArrayBuffer(f);
      };
      reader.readAsBinaryString(f);
    },
    exportDataFormat(values) {
      let list = [];
      values.forEach((value) => {
        let data = {};
        data.freightType = value["货运类型"];
        data.waybillNumber = value["运单号"];
        data.dispatchBill = value["发货单"];
        data.receivingNote = value["收货单"];
        data.plateNumber = value["车牌信息"];
        data.carColor = value["车牌颜色"];
        data.startString = value["发货时间"]
          ? this.formatTime(value["发货时间"])
          : "";
        data.endString = value["收货时间"]
          ? this.formatTime(value["收货时间"])
          : "";
        data.goodsName = value["商品名称"];
        data.freightWeight = value["商品数量"];
        list.push(data);
      });
      if (this.userInfo.roleType === "2") {
        buyerImport({
          logisticsExportDTOS: list,
          goodsSellId: this.queryParam.goodsSellId,
          orderId: this.item.orderId,
        }).then((data) => {
          if (data.code === 200) {
            this.$message.success(data.msg);
            this.findLogisticsList(this.item);
            return;
          }
          this.$message.warning(data.msg);
        });
      }
      if (this.userInfo.roleType === "1") {
        sellerImport({
          logisticsExportDTOS: list,
          goodsSellId: this.queryParam.goodsSellId,
          orderId: this.item.orderId,
        }).then((data) => {
          if (data.code === 200) {
            this.$message.success(data.msg);
            this.findLogisticsList(this.item);
            return;
          }
          this.$message.warning(data.msg);
        });
      }
    },

    // 时间转化格式
    formatTime(time) {
      if (isNaN(time) && !isNaN(Date.parse(time))) {
        return time;
      }
      return moment(new Date((time - 1) * 24 * 3600000) + 1)
        .subtract(1, "days")
        .subtract(70, "years")
        .subtract(8, "hours")
        .format("YYYY-MM-DD HH:mm");
    },

    // 往数据增加对应的图片地址
    getImgFn(src) {
      if (!src) {
        return;
      }
      const newList = JSON.parse(JSON.stringify(this.checkId));
      newList.map((value) => {
        this.tableData.find((v) => {
          if (v.id === value.id) {
            return (v.receivingNote = src);
          }
        });
      });
      const parmas = {
        logisticsExportDTOS: this.tableData,
        goodsSellId: this.queryParam.goodsSellId,
        orderId: this.queryParam.orderId,
        logisticsIds: this.logisticsIds,
      };
      buyerImport(parmas).then((data) => {
        if (data.code === 200) {
          this.$message.success(data.msg);
          return;
        }
        return this.$message.warning(data.msg);
      });
    },
    // 是否选中
    handleSelectionChange(value) {
      this.checkId = value;
      if (value.length > 0) {
        this.isOpen = false;
      } else {
        this.isOpen = true;
      }
      console.log("value", this.checkId);
      const idList = [];
      this.checkId.forEach((value, index, array) => {
        idList.push(value.id);
      });
      console.log("idList", idList.toString());
      this.logisticsIds = idList.toString();
    },
    // 校验文件格式
    judgeFileType(fileName) {
      // 后缀获取
      var suffix = "";
      var result = "";
      try {
        var flieArr = fileName.split(".");
        suffix = flieArr[flieArr.length - 1];
      } catch (err) {
        suffix = "";
      }
      // fileName无后缀返回 false
      if (!suffix) {
        result = false;
        return result;
      }
      // 图片格式
      var imglist = ["png", "jpg", "jpeg", "bmp", "gif"];
      // 进行图片匹配
      result = imglist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = "image";
        return result;
      }
      // 匹配PDF
      var pdflist = ["pdf"];
      result = pdflist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = "pdf";
        return result;
      }
    },
    // 文件预览
    filePreview(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
</style>
