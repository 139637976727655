<!--
 * @Author: Jerry
 * @Date: 2021-01-26 14:34:53
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-23 23:58:58
 * @Description: file content
-->
<template>
  <div class="ordertitle bg_f4f4 Ptp15 Pbm15 Plf15 Prt15">
    <div class="column1" style="width: 304px">
      <div class="checkbox F14" v-show="isCheckedAll">
        <el-checkbox
          class="Prt50"
          @change="handleCheckAllChange"
          v-model="checkedAll"
          >全选</el-checkbox
        >
      </div>
      <span class="tag">近三个月订单</span>
    </div>
    <div class="column2" style="width: 130px">
      <span class="tag">商品数量</span>
    </div>
    <div class="column3" style="width: 68px">
      <span class="tag">交货方式</span>
    </div>
    <div class="column4" style="width: 92px">
      <span class="tag">金额</span>
    </div>
    <div class="column5" style="width: 98px">
      <span class="tag">订单状态</span>
    </div>
    <div class="column6">
      <span class="tag">操作</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "OrderTitle",

  props: {
    checkedAll: {
      type: Boolean,
      default: false,
    },
    isCheckedAll: {
      type: Boolean,
      default: false,
    }
  },
  /* data() {
    return {
      checkedAll: false,
    };
  }, */
  methods: {
    handleCheckAllChange() {
      this.$emit("changeCheck", this.checkedAll);
    },
  },
};
</script>
<style lang="scss" scoped>
.ordertitle {
  display: flex;
  justify-content: space-between;
  .checkbox {
    display: inline-block;
  }
  .tag {
    font-size: 14px;
  }
}
</style>
