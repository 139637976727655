<template>
  <div class="Prt20 Plf20 Ptp20">
    <div class="Mbm20">
      <el-button type="primary" size="small" @click="addList">新增运单</el-button>
      <el-upload style="display: inline-block; margin-left: 10px" action="https://jsonplaceholder.typicode.com/posts/" :on-change="exportData" :auto-upload="false" :show-file-list="false" v-if="delivery === 1">
        <el-button type="primary" size="small">excel导入运单</el-button>
      </el-upload>
      <div style="display: inline-block; margin-left: 10px">
        <logisticsUpload moduleType="05" :onChange="getImgFn" btnTitle="批量上传发货单" :isDisabled="isOpen" />
      </div>
    </div>
    <div>
      <el-checkbox v-model="isCheck" @change="onChangeCheck" /> 全选
    </div>
    <div v-if="list.length > 0" style="max-height: 500px; overflow-y: auto">
      <div v-for="(item, index) in list" :key="index" class="list-item">
        <el-form label-position="right" label-width="120px" :inline="true" size="mini">
          <el-checkbox v-model="item.check" @change="onChangeCheckbox" />
          <el-form-item label-width="100px" label="序号：">
            {{ index + 1 }}
          </el-form-item>
          <el-form-item label="上传发货单：">
            <el-button type="text" size="mini" v-model="item.dispatchBill" @click="filePreview(item.dispatchBill)" v-if="judgeFileType(item.dispatchBill) == 'pdf' && item.dispatchBill">查看发货单</el-button>
            <el-image v-if="judgeFileType(item.dispatchBill) == 'image' && item.dispatchBill" :src="item.dispatchBill" v-model="item.dispatchBill" style="width: 100px" :preview-src-list="[ item.dispatchBill ]" :z-index="9999999" />
            <!--            <logisticsUpload-->
            <!--              v-model="item.dispatchBill"-->
            <!--              moduleType="05"-->
            <!--            ></logisticsUpload>-->
          </el-form-item>
          <el-form-item label="状态：">
            <el-switch active-text="正常" inactive-text="异常" v-model="item.status" active-color="#13ce66" inactive-color="#ff4949" :active-value="0" :inactive-value="1">
            </el-switch>
          </el-form-item>
        </el-form>
        <el-form label-position="right" label-width="120px" v-if="delivery == 1" :inline="true" size="mini">
          <el-form-item label="车牌信息：">
            <el-input v-model="item.plateNumber" style="width: 120px"></el-input>
          </el-form-item>
          <el-form-item label="车牌颜色：">
            <el-select v-model="item.carColor" style="width: 120px">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品名称：">
            <el-input v-model="item.goodsName" style="width: 175px"></el-input>
          </el-form-item>
          <el-form-item label="商品数量：">
            <el-input v-model="item.freightWeight" style="width: 175px"></el-input>
          </el-form-item>
          <el-form-item label="发货时间：">
            <el-date-picker v-model="item.freightTimeStart" type="datetime" placeholder="开始时间" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" style="width: 160px">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="收货时间：">
            <el-date-picker v-model="item.freightTimeEnd" type="datetime" placeholder="结束时间" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" style="width: 160px">
            </el-date-picker>
          </el-form-item>
          <el-button type="danger" size="mini" @click="delList(index)" style="margin-bottom: 15px">删除</el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
// import logisticsUpload from "../upload/logisticsUpload";
import logisticsUpload from "../upload/logisticsUploadBatch";
import XLSX from "xlsx";
import moment from "moment";

export default {
  components: { logisticsUpload },
  data() {
    return {
      list: [],
      isOpen: true,
      isCheck: false,
      options: [
        {
          value: "黄牌",
          label: "黄牌"
        },
        {
          value: "蓝牌",
          label: "蓝牌"
        },
        {
          value: "无",
          label: "无"
        }
      ]
    };
  },
  computed: {},
  watch: {},
  props: {
    delivery: {
      // 送货上门
      type: Number,
      default: 1
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    addList() {
      this.list.push({
        freightType: "2",
        status: 0
      });
    },
    delList(index) {
      this.list.splice(index, 1);
    },
    inputValidator(value) {
      if (value) {
        return true;
      } else {
        return false;
      }
    },
    getData() {
      let list = this.list;
      
      if (this.delivery == 1) {
        list.forEach(res => {
          res.freightTimeStart =
            new Date(res.freightTimeStart).getTime() / 1000;
            res.freightTimeStart=res.freightTimeStart+''
          res.freightTimeEnd = new Date(res.freightTimeEnd).getTime() / 1000;
           res.freightTimeEnd=res.freightTimeEnd+''
        });
      }
      
      return list;
    },

    isDispatchBillTip() {
      // 没有完全上传发货单进行警告提示
      this.$confirm(
        "发货单上传的不完整，点击确定继续保存，点击取消前去上传补充！",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      )
        .then(() => {
          this.$emit("passEvent");
        })
        .catch(() => {});
      return true;
    },
    checkData() {
      if (this.list.length == 0 && !this.isEdit) {
        this.$message({
          type: "error",
          message: "请至少填写一条数据"
        });
        return false;
      }

      // 判断是否完全上传了发货单
      let isUploadDispatchBill = true;

      let warningData = [];
      for (let x = 0; x < this.list.length; x++) {
        let res = this.list[x];
        if (!res.plateNumber && this.delivery == 1) {
          this.$message({
            type: "error",
            message: "请完整的填写车牌信息"
          });
          return false;
        }
        if (!res.carColor && this.delivery == 1) {
          this.$message({
            type: "error",
            message: "请完整的填写车牌颜色"
          });
          return false;
        }
        if (!res.dispatchBill) {
          isUploadDispatchBill = false;
        }

        if (!res.goodsName && this.delivery == 1) {
          this.$message({
            type: "error",
            message: "请完整的填写商品名称"
          });
          return false;
        }
        if (!res.freightWeight && this.delivery == 1) {
          this.$message({
            type: "error",
            message: "请完整的填写商品数量"
          });
          return false;
        }
        if (!res.freightTimeStart && this.delivery == 1) {
          this.$message({
            type: "error",
            message: "请完整的填写发货时间"
          });
          return false;
        }
        if (!res.freightTimeEnd && this.delivery == 1) {
          this.$message({
            type: "error",
            message: "请完整的填写收货时间"
          });
          return false;
        }
        let diffHours = moment(res.freightTimeEnd).diff(
          moment(res.freightTimeStart),
          "hours"
        );
        if (diffHours > 168) {
          this.$message({
            type: "error",
            message: "序号：" + (x + 1) + "条数据 货运时间区间不能相隔超过一周"
          });
          return false;
        }
        if (diffHours <= 168 && diffHours >= 72) {
          warningData.push(x + 1);
        }
      }

      if (warningData.length == 0) {
        if (isUploadDispatchBill) {
          return true;
        }
        this.isDispatchBillTip();
      } else {
        let index = warningData.join("，");
        this.$confirm(
          "序号" +
            index +
            "的数据货运时间时长超过72小时，点击确定继续保存，点击取消前去修改！",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        )
          .then(() => {
            // 没有完全上传发货单进行警告提示
            this.isDispatchBillTip();
          })
          .catch(() => {});
      }
    },
    showExportExcel() {
      this.$refs.exportExcel.showModal();
    },
    exportData(file) {
      var that = this;
      // 拿取文件对象
      var f = file.raw;
      // 用FileReader来读取
      var reader = new FileReader();
      // 重写FileReader上的readAsBinaryString方法
      FileReader.prototype.readAsBinaryString = function(f) {
        var binary = "";
        var wb; // 读取完成的数据
        var outdata; // 你需要的数据
        var reader = new FileReader();
        reader.onload = function(e) {
          // 读取成Uint8Array，再转换为Unicode编码（Unicode占两个字节）
          var bytes = new Uint8Array(reader.result);
          var length = bytes.byteLength;
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          // 接下来就是xlsx了，具体可看api
          wb = XLSX.read(binary, {
            type: "binary"
          });
          outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
          // 自定义方法向父组件传递数据
          console.log(outdata);
          // 校验获取到的信息是否符合规定
          that.checkExportData(outdata);
        };
        reader.readAsArrayBuffer(f);
      };
      reader.readAsBinaryString(f);
    },
    checkExportData(data) {
      let msg = "";
      let isPass = true;
      for (let x = 0; x < data.length; x++) {
        let result = data[x];
        if (!result["车牌信息"]) {
          msg = "请完整填写第" + (x + 1) + "行的车牌信息";
          isPass = false;
          break;
        }
        if (!result["发货时间"]) {
          msg = "请完整填写第" + (x + 1) + "行的发货时间";
          isPass = false;
          break;
        }
        if (!result["收货时间"]) {
          msg = "请完整填写第" + (x + 1) + "行的货收货时间";
          isPass = false;
          break;
        }
      }
      if (isPass) {
        for (let x = 0; x < data.length; x++) {
          let result = data[x];
          this.list.push({
            status: 0,
            freightType: "2",
            dispatchBill: result["发货单"],
            plateNumber: result["车牌信息"],
            carColor: result["车牌颜色"],
            goodsName: result["商品名称"],
            freightWeight: result["商品数量"],
            freightTimeStart: this.formatTime(result["发货时间"]),
            freightTimeEnd: this.formatTime(result["收货时间"])
          });
        }
      } else {
        this.$message({
          type: "warning",
          message: msg
        });
        return false;
      }
    },
    validatorTime(rule, value, callback) {
      console.log(rule);
    },

    // 时间转化格式
    formatTime(serial,format='-') {
      if (isNaN(serial) && !isNaN(Date.parse(serial))) {
        return serial;
      }
var utc_days=Math.floor(serial-25569);
var utc_value=utc_days*86400;
var date_info =new Date(utc_value*1000)
var fractional_day =serial-Math.floor(serial)+0.0000001;
var total_seconds=Math.floor(86400*fractional_day);
var seconds=total_seconds%60
total_seconds-=seconds;
var hours=Math.floor(total_seconds / (60 * 60));
var minutes=Math.floor(total_seconds / 60 )% 60;
var daytime= new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds )
var returnDate=date_info.getFullYear();
returnDate += format +(date_info.getMonth() < 9?'0'+( date_info.getMonth()+ 1):( date_info.getMonth( )+ 1));
returnDate += format + (date_info.getDate()< 10 ?'0'+ date_info.getDate():date_info.getDate());
returnDate += ' ' + (daytime.getHours()< 10 ?'0'+ daytime.getHours():daytime.getHours());
returnDate += ':' + (daytime.getMinutes()< 10 ?'0'+ daytime.getMinutes():daytime.getMinutes());
return returnDate
    },
    // 批量上传发货单
    getImgFn(src) {
      if (!src) {
        return;
      }
      const newList = JSON.parse(JSON.stringify(this.list));
      newList.map(v => {
        if (v.check) {
          return (v.dispatchBill = src);
        }
      });
      this.list = newList;
      console.log("img", this.list);
    },
    // 是否选中
    onChangeCheckbox(value) {
      const found = this.list.find(element => {
        console.log(1, element.check);
        if (element.check === true) {
          return true;
        }
      });
      if (!found) {
        return (this.isOpen = true);
      }
      this.isOpen = false;
    },

    // 校验文件格式
    judgeFileType(fileName) {
      // 后缀获取
      var suffix = "";
      var result = "";
      try {
        var flieArr = fileName.split(".");
        suffix = flieArr[flieArr.length - 1];
      } catch (err) {
        suffix = "";
      }
      // fileName无后缀返回 false
      if (!suffix) {
        result = false;
        return result;
      }
      // 图片格式
      var imglist = ["png", "jpg", "jpeg", "bmp", "gif"];
      // 进行图片匹配
      result = imglist.some(function(item) {
        return item == suffix;
      });
      if (result) {
        result = "image";
        return result;
      }
      // 匹配PDF
      var pdflist = ["pdf"];
      result = pdflist.some(function(item) {
        return item == suffix;
      });
      if (result) {
        result = "pdf";
        return result;
      }
    },
    // 文件预览
    filePreview(url) {
      window.open(url, "_blank");
    },
    onChangeCheck(type) {
      console.log("check1", this.list);
      if (this.isCheck) {
        return this.list.map(v => {
          v.check = true;
          return (this.isOpen = false);
        });
      }
      if (!this.isCheck) {
        console.log("check2", this.isCheck);
        return this.list.map(v => {
          v.check = false;
          return (this.isOpen = true);
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.list-item {
  border-bottom: 1px solid #cccccc;
  margin-bottom: 15px;
  &:last-child {
    border-bottom: none;
  }
}
</style>
