<template>
  <div class="goodsorder">
    <div class="goodsdescribe Ptp15 Pbm15 Plf15 Prt15">
      <div class="column1">
        <div class="wid70 het70 columnimg Prt15">
          <slot name="img"> </slot>
        </div>
        <div class="text">
          <slot name="goodsdetail"> </slot>
        </div>
      </div>
      <div class="column2">
        <slot name="column2"> </slot>
      </div>
      <div class="column3">
        <slot name="column3"> </slot>
      </div>
      <div class="column4">
        <slot name="column4"> </slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "GoodsOrder",
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.goodsorder {
  .goodsdescribe {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */

    .column1 {
      width: 370px;
      .columnimg {
        display: inline-block;
        vertical-align: top;
        /* border: 1px solid black; */
        .img {
          width: 100%;
          height: 100%;
        }
      }
      .text {
        width: 220px;
        display: inline-block;
        vertical-align: top;
        /*  border: 1px solid black; */
      }
    }
  }
}
</style>
