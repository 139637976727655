<!--
 * @Author: Jerry
 * @Date: 2021-03-14 22:34:35
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-26 09:41:23
 * @Description: 物流列表
-->
<template>
  <div style="max-height: 500px; overflow:auto;">
    <div v-if="(type === '2' || type === '3') && userInfo.roleType === '1' && !isEdit" style="padding-bottom: 10px">
      <Batch moduleType="05" :onChange="(url)=>{getImgFn('dispatchBill',url)}" btnTitle="批量上传发货单"/>
    </div>
    <div v-if="(type === '2' || type === '3') && userInfo.roleType === '2' && !isEdit" style="padding-bottom: 10px">
      <Batch moduleType="05" :onChange="(url)=>{getImgFn('receivingNote',url)}" btnTitle="批量上传收货单"/>
    </div>
    <el-table
        v-show="type == '2' || type == '4' || type == '3'"
        :data="tableData"
        border
        size="mini"
        width="600px"
        @selection-change="handleSelectionChange"
    >
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column prop="freightType" label="货运类型" width="120">
        <template slot-scope="scope">
          {{ freightType[scope.row.freightType] }}
        </template>
      </el-table-column>
      <el-table-column prop="transportNo" label="订单号"/>
      <el-table-column prop="waybillNumber" label="运单号"/>
      <el-table-column prop="dispatchBill" label="发货单">
        <template slot-scope="scope">
          <span v-if="!scope.row.dispatchBill">暂无发货单</span>
          <div v-else>
          <el-button
              type="text"
              size="mini"
              @click="filePreview(scope.row.dispatchBill)"
              v-if="judgeFileType(scope.row.dispatchBill) == 'pdf' && scope.row.dispatchBill"
          >查看发货单
          </el-button>
          <el-image
              :src="scope.row.dispatchBill"
              style="width: 100px"
              :preview-src-list="[scope.row.dispatchBill]"
              v-if="judgeFileType(scope.row.dispatchBill) == 'image' && scope.row.dispatchBill"
              :z-index="99999"/>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="receivingNote" label="收货单">
        <template slot-scope="scope">
          <span v-if="!scope.row.receivingNote">暂无收货单</span>
          <div v-else>
            <el-button
                type="text"
                size="mini"
                @click="filePreview(scope.row.receivingNote)"
                v-if="judgeFileType(scope.row.receivingNote) == 'pdf' && scope.row.receivingNote"
            >查看收货单
            </el-button>
            <el-image
                :src="scope.row.receivingNote"
                style="width: 100px"
                :preview-src-list="[scope.row.receivingNote]"
                v-if="judgeFileType(scope.row.receivingNote) == 'image' && scope.row.receivingNote"
                :z-index="99999"/>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="action" label="操作" v-if="!isEdit" width="110px">
        <template slot-scope="scope">
          <el-button
              @click="findLogisticsMessage(scope.row)"
              type="primary"
              size="mini"
          >查看详情
          </el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-table
        v-show="type == '1'"
        :data="tableData"
        style="width: 100%"
        border
        size="mini"
    >
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column prop="freightType" label="货运类型" width="120">
        <template slot-scope="scope">
          {{ freightType[scope.row.freightType] }}
        </template>
      </el-table-column>
      <el-table-column prop="waybillNumber" label="订单号"/>
      <el-table-column prop="transportNo" label="运单号"/>
      <el-table-column prop="action" label="操作">
        <template slot-scope="scope">
          <el-button
              @click="onClickDetails(scope.row)"
              type="primary"
              size="mini"
          >查看详情
          </el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <TransportInfo :visible="visible" :tableData="tableDataDetails" :onClickClose="onClickClose"/>
  </div>
</template>

<script>
  import {getTransportInfo, updateLogistics} from "@/api/product";
  import Batch from "../upload/logisticsUploadBatch";
  import {freightType} from "@/util/util";
  import {mapGetters} from "vuex";
  import TransportInfo from './transportInfo'

  export default {
    components: {TransportInfo, Batch},
    name: "",
    data() {
      return {
        freightType,
        visible: false,
        tableDataDetails: [],
        checkId: [],
        isOpen: true,
      };
    },
    props: {
      tableData: {
        type: Array,
        default: () => {
          return [];
        },
      },
      type: {
        type: String,
        default: "1",
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
      handUploadFiles: {
        type: Function,
        default: () => {
          return
        }
      }
    },
    computed: {
      ...mapGetters(["userInfo"])
    },
    watch: {},
    methods: {
      findLogisticsMessage(row) {
        this.$emit("findLogisticsMessage", row);
        // this.visible = true
      },

      // 是否选中
      handleSelectionChange(value) {
        this.checkId = []
        this.isOpen = value.length <= 0;
        for(let i =0; i<value.length; i++){
          this.checkId.push(value[i].id)
        }
        console.log(2,this.checkId)
      },
      // 文件预览
      filePreview(url) {
        window.open(url, "_blank");
      },
      // 校验文件格式
      judgeFileType(fileName) {
        // 后缀获取
        var suffix = "";
        var result = "";
        try {
          var flieArr = fileName.split(".");
          suffix = flieArr[flieArr.length - 1];
        } catch (err) {
          suffix = "";
        }
        // fileName无后缀返回 false
        if (!suffix) {
          result = false;
          return result;
        }
        // 图片格式
        var imglist = ["png", "jpg", "jpeg", "bmp", "gif"];
        // 进行图片匹配
        result = imglist.some(function (item) {
          return item == suffix;
        });
        if (result) {
          result = "image";
          return result;
        }
        // 匹配PDF
        var pdflist = ["pdf"];
        result = pdflist.some(function (item) {
          return item == suffix;
        });
        if (result) {
          result = "pdf";
          return result;
        }
      },
      onClickDetails(data) {
        console.log(data.transportNo)
        getTransportInfo({
          transportNo: data.transportNo,
          logisticsId: data.id,
          plateNumber: data.plateNumber,
          waybillNumber: data.waybillNumber
        }).then((res) => {
          console.log('getTransportInfo', res)
          if (res.code === 200) {
            this.tableDataDetails = res.data
          }
        });
        this.visible = true
      },
      onClickClose() {
        this.visible = false
      },

      getImgFn(key, src) {
        let newData = JSON.parse(JSON.stringify(this.tableData))
        if (!src) {
          return
        }
        for (let i = 0; i < this.checkId.length; i++) {
          let index = this.tableData.findIndex((e) => e.id === this.checkId[i])
          if (index > -1) {
            newData[index][key] = src
          }
        }
        this.handUploadFiles(newData) // 传回父组件更新旧数据
        this.handleImage(key, this.checkId.toString(), src)
      },
      handleImage(key, list, src) {
        updateLogistics({
          logisticsIds: list,
          dispatchBill: key === 'dispatchBill' ? src : null,
          receivingNote: key === 'receivingNote' ? src : null
        }).then((res) => {
          if (res.code === 200) {
            this.$message({
              type: "success",
              message: res.msg,
            });
            return
          }
          this.$message({
            type: "warning",
            message: res.msg,
          });
        })
      }
    },
  };
</script>

<style scoped>
</style>
