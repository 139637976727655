<!--
 * @Author: Jerry
 * @Date: 2021-03-12 19:21:00
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-26 09:43:04
 * @Description: 物流列表
-->
<template>
  <div>
    <el-dialog
        title="物流信息"
        :visible.sync="dialogVisible"
        width="1280px"
        top="5vh"
        :append-to-body="true"
    >
      <div>
        <logisticsTable
            ref="logisticsTable"
            @findLogisticsMessage="findLogisticsMessage"
            :tableData="tableData"
            :type="type"
            :handUploadFiles="handUploadFiles"
        ></logisticsTable>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 物流信息 -->
    <logisticsInfo ref="logisticsInfo"></logisticsInfo>
    <!-- 物流信息 地图打点 -->
    <logisticsInfoByMap ref="logisticsInfoByMap"></logisticsInfoByMap>
  </div>
</template>

<script>
  import { findLogisticsList } from "@/api/product";
  import logisticsInfo from "./logisticsInfo";
  import logisticsInfoByMap from "./logisticsInfoByMap2";
  import logisticsTable from "./logisticsTable";
  export default {
    name: "",
    data() {
      return {
        dialogVisible: false,
        tableData: [],
        queryParam: {},
        type: null
      };
    },
    watch: {},
    components: { logisticsInfo, logisticsInfoByMap, logisticsTable },
    methods: {
      showModal(item) {
        this.$set(this, "tableData", []);
        this.dialogVisible = true;
        this.findLogisticsList(item.orderId);
        this.queryParam.orderId = item.orderId
        this.queryParam.goodsSellId = item.goodsSellId
      },
      findLogisticsList(orderId) {
        findLogisticsList({
          orderId: orderId,
        }).then((res) => {
          if (res.success) {
            if (res.data.length > 0) {
              this.type = res.data[0].freightType;
              if(this.type == '1') {
                console.log('data', this.formatTableData(res.data))
                this.$set(this, "tableData", this.formatTableData(res.data));
              } else {
                this.$set(this, "tableData", this.formatTableData(res.data));
              }
            }
          }
        });
      },
      // 格式化物流信息表格数据
      formatTableData(value){

        let arr = []
        value.map((v, i)=>{
          if(!v.waybillNumber) {return}
          if (!v.webTransportNo) {
            console.log('webTransportNo', v)
            return arr.push(v)
          }
          let s1 = v.webTransportNo.split(',');
          s1.forEach((item)=>{
            let obj = {};
            obj.transportNo = item;
            obj.id = v.id
            obj.orderId = v.orderId
            obj.logisticsCompany = v.logisticsCompany
            obj.logisticsLogo = v.logisticsLogo
            obj.logisticsStatus = v.logisticsStatus
            obj.freightType = v.freightType
            obj.waybillNumber = v.waybillNumber
            obj.dispatchBill = v.dispatchBill
            obj.receivingNote = v.receivingNote
            obj.plateNumber = v.plateNumber
            obj.freightTimeStart = v.freightTimeStart
            obj.freightTimeEnd = v.freightTimeEnd
            obj.remark = v.remark
            obj.dataSort = v.dataSort
            obj.createUser = v.createUser
            obj.createDept = v.createDept
            obj.createTime = v.createTime
            obj.updateUser = v.updateUser
            obj.updateTime = v.updateTime
            obj.status = v.status
            obj.isDeleted = v.isDeleted
            obj.webOrderNum = v.webOrderNum
            obj.webFreightUnit = v.webFreightUnit
            obj.webOrderStatus = v.webOrderStatus
            obj.goodsName = v.goodsName
            obj.freightWeight = v.freightWeight
            return arr.push(obj)
          })
        })

        console.log('item-',arr)
        return arr
      },

      // 查看快递 详细信息
      findLogisticsMessage(row) {
        let type = row.freightType;
        let id = row.id;
        if (type == 3) {
          this.$refs.logisticsInfo.showModal(id, row);
        } else if (type == 2) {
          this.$refs.logisticsInfoByMap.showModal(id, row);
        }
      },
      // 更新子组件传的数据
      handUploadFiles(value){
        this.tableData = value
      }
    }
  };
</script>

<style scoped lang="scss"></style>
