var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orderoperation Ptp15 Pbm20 Prt15"},[_c('div',{staticClass:"operation"},[_c('div',{staticClass:"column5"},[_c('div',{staticClass:"co_green"},[_c('span',{staticClass:"orange"},[_vm._v(_vm._s(_vm.matchingOrderStatus(_vm.orderState)))])]),_c('div',{staticClass:"btn"},[_c('el-link',{on:{"click":function($event){$event.stopPropagation();return _vm.orderDetail()}}},[_vm._v("订单详情")])],1),_c('div',{staticClass:"btn"},[_c('el-link',{on:{"click":function($event){$event.stopPropagation();return _vm.seeContract()}}},[_vm._v("查看合同")])],1),_c('div',{staticClass:"btn"},[_c('el-link',{on:{"click":function($event){$event.stopPropagation();return _vm.logisticsInfo()}}},[_vm._v("物流信息")])],1),(_vm.orderInfo.sellerInvoice && _vm.userInfo.roleType == '1')?_c('div',{staticClass:"btn"},[_c('el-link',{on:{"click":function($event){$event.stopPropagation();return _vm.getSellerInvoice(_vm.orderInfo.orderId)}}},[_vm._v("发票信息")])],1):_vm._e()]),_c('div',{staticClass:"column6",staticStyle:{"width":"110px","position":"relative"}},[(
          (_vm.orderInfo.applyInvoice == '2' && _vm.userInfo.roleType == '2') ||
          (_vm.orderInfo.applyInvoice == '2' && _vm.userInfo.roleType == '1')
        )?_c('img',{staticStyle:{"width":"60px","position":"absolute","top":"-55px","right":"0px"},attrs:{"src":"/img/icon/openInvoice.png"}}):_vm._e(),(_vm.$route.name == 'AllOrders' && _vm.userInfo.roleType == '1')?_c('div',{staticClass:"Mbm5"},[(_vm.orderInfo)?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.upLoadInvoice(_vm.orderInfo.orderId)}}},[_vm._v("上传发票")]):_vm._e()],1):_vm._e(),(_vm.$route.name !== 'OrderDetermine' && _vm.orderState === '2')?_c('div',{staticClass:"Mbm5"},[_c('el-button',{staticStyle:{"margin":"5px 0 0 0"},attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.deleteOrder(_vm.orderInfo.roleType, _vm.orderInfo.orderId)}}},[_vm._v(" 删除订单 ")])],1):_vm._e(),(
          _vm.$route.name != 'OrderDetermine' &&
          _vm.orderState != '1' &&
          _vm.orderState != '2'
        )?_c('div',{staticClass:"Mbm5"},[(
            _vm.userInfo.roleType == '2' &&
            (_vm.orderInfo.dealCancelStatus == '' ||
              _vm.orderInfo.dealCancelStatus == '2')
          )?_c('el-button',{attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.buyerOrderRefund()}}},[_vm._v("取消交易")]):_vm._e(),(
            _vm.userInfo.roleType == '2' &&
            (_vm.orderInfo.dealCancelStatus == '' ||
              _vm.orderInfo.dealCancelStatus == '2')
          )?_c('br'):_vm._e(),_c('span',{staticClass:"orange curPointer",on:{"click":function($event){return _vm.getAuditResultByDataId(_vm.orderInfo, '4')}}},[_vm._v(" "+_vm._s(_vm.$matching(_vm.orderInfo.dealCancelStatus, _vm.dealCancelStatus))+" ")])],1):_vm._e(),(_vm.$route.name == 'OrderDetermine' || _vm.orderState == '1')?_c('div',[(_vm.userInfo.roleType == '1')?_c('el-button',{attrs:{"type":"success","size":"mini"},on:{"click":function($event){return _vm.buyerAffirmOrder()}}},[_vm._v("订单确认")]):_vm._e(),_c('el-button',{staticStyle:{"margin":"5px 0 0 0"},attrs:{"type":"danger","size":"mini"},on:{"click":_vm.buyerCancelOrder}},[_vm._v("订单取消")])],1):_vm._e(),(_vm.$route.name == 'WaitPayment')?_c('div',[(_vm.userInfo.roleType == '2')?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.payment()}}},[_vm._v("付款")]):_vm._e()],1):_vm._e(),(_vm.$route.name == 'WaitSendGoods')?_c('div',[(_vm.userInfo.roleType == '1')?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.deliverGoods()}}},[_vm._v("填写货运信息")]):_vm._e()],1):_vm._e(),(_vm.$route.name == 'WaitReceived')?_c('div',[(
            _vm.userInfo.roleType == '2' &&
            _vm.orderInfo.orderStatus != '5' &&
            _vm.orderInfo.orderStatus != '7'
          )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.affirmGetOrder()}}},[_vm._v("确认收货")]):_vm._e(),(_vm.orderInfo.orderStatus == '6' && _vm.userInfo.roleType == '2')?_c('el-link',{staticClass:"Mtp5",attrs:{"size":"mini","type":"text"},on:{"click":function($event){return _vm.getAuditResultByDataId(_vm.orderInfo, '7')}}},[_vm._v("查看失败原因")]):_vm._e(),(
            _vm.userInfo.roleType == '1' &&
            _vm.orderInfo.orderStatus != '5' &&
            _vm.orderInfo.orderStatus != '7'
          )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.editShipInfo()}}},[_vm._v("修改货运信息")]):_vm._e()],1):_vm._e(),(_vm.$route.name == 'HaveSettled')?_c('div'):_vm._e(),(_vm.$route.name == 'TransactionCancel')?_c('div',[(_vm.userInfo.roleType == '2')?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.reStartOrder(_vm.orderInfo)}}},[_vm._v("重新下单")]):_vm._e(),(
            _vm.userInfo.roleType == '1' &&
            _vm.orderInfo.supplierDealCancelStatus == '2'
          )?_c('div',{staticClass:"F12 co_f00 Mtp5"},[_vm._v(" 不同意交易取消 ")]):_vm._e(),(
            _vm.userInfo.roleType == '1' &&
            _vm.orderInfo.supplierDealCancelStatus == '3'
          )?_c('div',{staticClass:"F12 co_green Mtp5"},[_vm._v(" 同意交易取消 ")]):_vm._e(),(
            _vm.userInfo.roleType == '1' &&
            _vm.orderInfo.supplierDealCancelStatus != '1'
          )?_c('div',{staticClass:"F12 co_999 Mtp5"},[_vm._v(" 理由："+_vm._s(_vm.orderInfo.supplierDealCancelReason)+" ")]):_vm._e(),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.orderInfo.dealCancelReason,"placement":"top-start"}},[(
              _vm.userInfo.roleType == '1' &&
              _vm.orderInfo.supplierDealCancelStatus == '1'
            )?_c('div',{staticClass:"F12 co_999 Mbm5"},[_vm._v(" 查看申请原因 ")]):_vm._e()]),(
            _vm.userInfo.roleType == '1' &&
            _vm.orderInfo.supplierDealCancelStatus == '1'
          )?_c('el-button',{attrs:{"type":"success","size":"mini"},on:{"click":function($event){return _vm.buyerDealCancel('1', _vm.orderInfo.orderId)}}},[_vm._v("同意取消")]):_vm._e(),(
            _vm.userInfo.roleType == '1' &&
            _vm.orderInfo.supplierDealCancelStatus == '1'
          )?_c('el-button',{staticStyle:{"margin":"5px 0 0 0"},attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.buyerDealCancel('2', _vm.orderInfo.orderId)}}},[_vm._v("不同意取消")]):_vm._e(),(
            _vm.userInfo.roleType === '1' || _vm.orderInfo.dealCancelStatus == '3'
          )?_c('el-button',{staticStyle:{"margin":"5px 0 0 0"},attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.deleteOrder(_vm.orderInfo.roleType, _vm.orderInfo.orderId)}}},[_vm._v(" 删除订单 ")]):_vm._e()],1):_vm._e()])]),(_vm.confDialog.dialogVisible)?_c('el-dialog',{attrs:{"title":"审核信息","visible":_vm.confDialog.dialogVisible,"width":"30%"},on:{"update:visible":function($event){return _vm.$set(_vm.confDialog, "dialogVisible", $event)}}},[_vm._v(" 系统审核："+_vm._s(_vm.confDialog.auditReason)+" "),_c('br'),_vm._v(" 供应商审核："+_vm._s(_vm.confDialog.supplierDealCancelReason)+" "),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.confDialog.dialogVisible = false}}},[_vm._v("确 定")])],1)]):_vm._e(),_c('upLoadInvoice',{ref:"upLoadInvoice",on:{"submit":_vm.uploadSellerInvoice}}),_c('viewInvoice',{ref:"viewInvoice"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }