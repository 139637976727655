<template>
  <div>
    <el-dialog
      title="发货"
      :visible.sync="dialogVisible"
      width="1000px"
      top="5vh"
      :close-on-click-modal="false"
    >
      <div>
        <el-radio-group v-model="freightType" size="medium">
          <el-radio-button :label="1" :disabled="orderInfo.delivery == 2">途啦网络货运</el-radio-button>
          <el-radio-button :label="2">第三方物流</el-radio-button>
          <el-radio-button :label="3" :disabled="orderInfo.delivery == 2">快递</el-radio-button>
        </el-radio-group>
        <div>
          <!-- 网络货运平台、快递 -->
          <deliverOrderNumber
            v-if="freightType == 1 || freightType == 3"
            ref="deliverOrderNumber"
            class="deliverOrderNumber"
            :freightType="freightType"
            @passEvent="deliverOrderLogisticsEvent"
          ></deliverOrderNumber>
          <!-- 第三方物流 -->
          <thirdPartyLogistics
            v-if="freightType == 2 || freightType == 4"
            ref="thirdPartyLogistics"
            :delivery="orderInfo.delivery"
            @passEvent="thirdPartyLogisticsEvent"
          ></thirdPartyLogistics>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 订单发货
// 货运类型(1：网络货运平台，2：第三方物流，3：快递，4：其他)
// 1、网络货运平台：填写订单号，可多个
// 2、第三方物流：车牌信息、司机姓名、电话、发货时间
// 3、快递：填写订单号，可多个
// 4、其他：发货单、提货单

import deliverOrderNumber from "./deliverOrderNumber";
import thirdPartyLogistics from "./thirdPartyLogistics";
import { sellerShipments } from "@/api/product";
export default {
  data() {
    return {
      freightList: [
        {
          label: 1,
          value: "途啦网络货运",
        },
        {
          label: 2,
          value: "第三方物流",
        },
        {
          label: 3,
          value: "快递",
        },
      ],
      dialogVisible: false,
      orderInfo: {},
      freightType: 1,
    };
  },
  components: { deliverOrderNumber, thirdPartyLogistics },
  methods: {
    showModal(orderInfo) {
      this.dialogVisible = true;
      this.freightType = 1;
      this.$set(this, "orderInfo", orderInfo);
      this.$nextTick(() => {
        if (this.orderInfo.delivery == 2) {
          this.freightType = 2;
        }
        this.$refs.deliverOrderNumber.clearFrom();
      });
    },
    submit() {
      if (this.freightType == 1 || this.freightType == 3) {
        if (this.$refs.deliverOrderNumber.checkData()) {
          this.deliverOrderLogisticsEvent()
        }
      } else if (this.freightType == 2) {
        if (this.$refs.thirdPartyLogistics.checkData()) {
          this.thirdPartyLogisticsEvent();
        }
      } else if (this.freightType == 4) {
        console.log(this.freightType);
      }
    },

    // 快递的子组件回调方法
    deliverOrderLogisticsEvent(){
      let logisticsDTO = {
        logisticsList: this.$refs.deliverOrderNumber.getData(),
        orderId: this.orderInfo.orderId,
      };
      this.sellerShipments(logisticsDTO);
    },
    // 第三方物流的子组件回调方法
    thirdPartyLogisticsEvent() {
      let logisticsDTO = {
        logisticsList: this.$refs.thirdPartyLogistics.getData(),
        orderId: this.orderInfo.orderId,
      };
      this.sellerShipments(logisticsDTO);
    },
    // 卖家发货
    sellerShipments(data) {
      sellerShipments(data).then((res) => {
        if (res.success) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.dialogVisible = false;
          this.$emit("refreshData");
        } else {
          this.$message({
            type: "danger",
            message: res.msg,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scope>
.deliverOrderNumber {
  padding: 30px 20px;
}
</style>
