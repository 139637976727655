<!--
 * @Author: hsn
 * @Date: 2021-01-22 15:41:57
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-16 22:39:09
 * @Description: file content
-->
<template>
  <div>
    <el-dialog title="合同" :visible.sync="viewContract" width="500px">
      <div v-if="judgeFileType(contract) == 'image'" class="textC">
        <el-image style="width: 250px" fit="contain" :src="contract">
        </el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="viewContract = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 图片预览 -->
    <elImageViewer
      ref="elImageViewer"
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="previewSrcList"
    ></elImageViewer>
  </div>
</template>
<script>
import elImageViewer from "element-ui/packages/image/src/image-viewer";
import { mapGetters } from "vuex";
let prevOverflow = "";
export default {
  data() {
    return {
      viewContract: false,
      contract: "",
      showViewer: false,
      previewSrcList: [],
    };
  },
  components: { elImageViewer },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  props: {
    buyerContract: {
      type: String,
      default: "",
    },
    sellContract: {
      type: String,
      default: "",
    },
  },
  //   created() {

  //   },
  methods: {
    showDialog(contract) {
      if (contract == "") {
        this.$message({
          type: "warning",
          message: "暂无合同文件信息",
        });
        return false;
      }
      if (this.judgeFileType(contract) == "pdf") {
        this.filePreview(contract);
      } else {
        prevOverflow = document.body.style.overflow;
        document.body.style.overflow = "hidden";
        this.contract = contract;
        this.previewSrcList = [contract];
        this.showViewer = true;
        // this.viewContract = true;
      }
    },
    // 文件预览
    filePreview(url) {
      window.open(url, "_blank");
    },
    // 校验文件格式
    judgeFileType(fileName) {
      // 后缀获取
      var suffix = "";
      var result = "";
      try {
        var flieArr = fileName.split(".");
        suffix = flieArr[flieArr.length - 1];
      } catch (err) {
        suffix = "";
      }
      // fileName无后缀返回 false
      if (!suffix) {
        result = false;
        return result;
      }
      // 图片格式
      var imglist = ["png", "jpg", "jpeg", "bmp", "gif"];
      // 进行图片匹配
      result = imglist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = "image";
        return result;
      }
      // 匹配PDF
      var pdflist = ["pdf"];
      result = pdflist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = "pdf";
        return result;
      }
    },
    closeViewer() {
      document.body.style.overflow = prevOverflow;
      this.showViewer = false;
    },
  },
};
</script>
<style scoped></style>
