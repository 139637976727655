<template>
  <div>
    <el-dialog :title="'上传发票文件'" :visible.sync="dialog" width="600px" :close-on-click-modal="false">
      <div>
        <div>
          发票代码：<el-input
            v-model="InvoiceCode"
            size="small"
            style="width: 300px; margin-bottom: 10px"
          ></el-input>
        </div>
        <div>
          发票号码：<el-input
            v-model="InvoiceNo"
            size="small"
            style="width: 300px; margin-bottom: 10px"
          ></el-input>
        </div>
        <upload ref="upload" :list="fileUrl" moduleType="03"></upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="submit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import upload from "@/components/upload/fileUpload.vue";
export default {
  data() {
    return {
      dialog: false,
      // 图片地址
      fileUrl: [],
      // 发票代码
      InvoiceCode: "",
      // 发票编码
      InvoiceNo: "",
      id: "",
    };
  },
  components: { upload },
  mounted() {},
  methods: {
    showModal(id) {
      this.id = id;
      this.InvoiceCode = "";
      this.InvoiceNo = "";
      this.dialog = true;
      this.$nextTick(() => {
        // this.$refs.upload.list = [];
        this.fileUrl = [];
      })
    },
    closeModal() {
      this.dialog = false;
      this.id = "";
      this.InvoiceCode = "";
      this.InvoiceNo = "";
    },
    submit() {
      let fileUrl = this.$refs.upload.getFileList();
      if (!fileUrl) {
        this.$message({
          type: 'warning',
          message: '请上传发票文件'
        })
        return false
      }
      if (!this.InvoiceCode) {
        this.$message({
          type: 'warning',
          message: '请填写发票代码'
        })
        return false
      }
      if (!this.InvoiceNo) {
        this.$message({
          type: 'warning',
          message: '请填写发票编码'
        })
        return false
      }
      this.$emit("submit", {
        id: this.id,
        sellerInvoiceCode: this.InvoiceCode,
        sellerInvoiceNo: this.InvoiceNo,
        sellerInvoice: fileUrl,
      });
    },
  },
};
</script>