<template>
  <div>
    <el-upload
      action="/shop/fileservice/uploadFile"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
      :before-upload="beforeUpload"
      :headers="token"
      :data="{ moduleType: moduleType }"
      :file-list="fileList"
      accept=".jpg,.jpeg,.png,.pdf,.JPG,.JPEG,.PDF"
    >
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">只能上传图片和PDF文件</div>
    </el-upload>
  </div>
</template>

<script>
import { getToken } from "@/util/auth";
export default {
  data() {
    return {
      token: {},
    };
  },
  props: {
    moduleType: {
      type: String,
      default: () => {
        return "01";
      },
    },
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  mounted() {
    this.token = {
      "Blade-Auth": "bearer " + getToken(),
    };
  },
  methods: {
    // 文件上传成功
    handleSuccess(res) {
      this.fileList.push({
        url: res.data[0].filePath,
        name: res.data[0].fileNewName,
      });
    },
    // 上传前判断
    beforeUpload(file) {
      if (this.fileList.length > 0) {
        this.$message({
          type: "warning",
          message: "只能上传一个文件",
        });
        return false;
      }
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isPDF = file.type === "application/pdf";
      // const isLt2M = file.size / 1024 / 1024 < 2;
      if (!(isJPG || isPNG || isPDF)) {
        this.$message.error("上传文件图片只能是JPG、JPEG、PNG、PDF 格式!");
        return false;
      }
      return true
      // if (!isLt2M) {
      //   this.$message.error("上传头像图片大小不能超过 2MB!");
      // }
    },
    // 移除
    handleRemove(file) {
      let url = file.response ? file.response.data[0].filePath : file.url;
      for (let x = 0; x < this.fileList.length; x++) {
        if (this.fileList[x].url == url) {
          this.fileList.splice(x, 1);
        }
      }
    },
    // 预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 获取列表数据
    getFileList() {
      let fileList = "";
      for (let x = 0; x < this.fileList.length; x++) {
        fileList = fileList + this.fileList[x].url
      }
      return fileList;
    },
  },
  computed: {
    fileList() {
      if (this.validatenull(this.list)) {
        return []
      } else {
        let list = [
          {
            name: this.list,
            url: this.list,
          },
        ];
        return list;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>